import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import FormSelect from '../Form/FormSelect';
import { useTheme } from '@mui/material/styles';

const SelectQuery = props => {
    const theme = useTheme();
    const queries = useSelector(state => state.queries);
    const [value, setValue] = useState(props.value);

    const matched = props.limit ?
        Object.entries(props.limit).map(([k, v]) => queries.data.filter(y => y[k] === v)).flat() :
        queries.data || []
    const options = matched.map(x => ({
        value: x.Id,
        connection: x.connection,
        label: x.Name,
        // icon: <JoinLeftIcon />,
        link: 'queries'
    }))

    useEffect(() => {
        setValue(props.value)
    }, [props])
    const sx = {
        my: 0.5,
        color: theme.palette.primary.main,
        background: theme.palette.offwhite.main,
        borderRadius: '1rem',
        overflow: 'auto',
        width: '100%',
        '& .MuiGrid-root': { pt: 0 }
    }
    return (
        <FormSelect
            name={props.name || "query"}
            options={options}
            value={value || ''}
            handler={props.handler}
            disableUnderline={props.disableUnderline}
        />
    )

}

export default SelectQuery;