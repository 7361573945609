import { createSlice } from '@reduxjs/toolkit'

const slices = {}
const domains = ["connections", "executions", "jobs", "queries", "schemas", "sysconfig", "systems", "users"]
domains.forEach(x => {
    slices[x] = createSlice({
        name: x,
        initialState: {},
        reducers: {
            [`set${x[0].toUpperCase() + x.slice(1)}`]: (state, data) => {
                state.data = data.payload
                state.lastUpdated = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString()
            },
        },
    })
})

export const { setConnections } = slices['connections'].actions
export const { setExecutions } = slices['executions'].actions
export const { setJobs } = slices['jobs'].actions
export const { setQueries } = slices['queries'].actions
export const { setSchemas } = slices['schemas'].actions
export const { setSysconfig } = slices['sysconfig'].actions
export const { setSystems } = slices['systems'].actions
export const { setUsers } = slices['users'].actions

export const connectionsReducer = slices['connections'].reducer
export const executionsReducer = slices['executions'].reducer
export const jobsReducer = slices['jobs'].reducer
export const queriesReducer = slices['queries'].reducer
export const schemasReducer = slices['schemas'].reducer
export const sysconfigReducer = slices['sysconfig'].reducer
export const systemsReducer = slices['systems'].reducer
export const usersReducer = slices['users'].reducer