import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from "react-oidc-context";
import { UserManager } from 'oidc-client-ts';

import App from './App';

import { persistor, store } from './components/Cache/store'
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux'
import cognitoAuthConfig from './config';

const userManager = new UserManager(cognitoAuthConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

// wrap the application with AuthProvider
root.render(
  // <React.StrictMode>
    <AuthProvider
    // {...cognitoAuthConfig}
      userManager={userManager}
      onSigninCallback={() => { window.location.href = "/"; }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </AuthProvider>
  // </React.StrictMode>
);