import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';

export default function Time(props) {
    // const cronArray = (props.cron || "").replace(/[cron()]/g, "").split(" ");
    // const date = new Date()
    // date.setUTCFullYear(props.start ? props.start.substring(0, 4) : new Date().getFullYear())
    // date.setUTCMonth(props.start ? props.start.substring(5, 7) - 1 : new Date().getMonth())
    // date.setUTCDate(props.start ? props.start.substring(8, 10) : new Date().getDate())
    // date.setUTCHours(props.start ? props.start.substring(11, 13) : parseInt(cronArray[1], 10))
    // date.setUTCMinutes(props.start ? props.start.substring(14, 16) : parseInt(cronArray[0], 10))
    // date.setUTCSeconds(props.start ? props.start.substring(17, 19) : 0)

    // const hourly = ((cronArray[1] || "").indexOf("/") > -1)

    const { date, hourly } = makeDate(props)

    return (
        <Badge
            badgeContent={hourly ? "H" : null}
            color="neutral"
            sx={{
                display: props.date ? 'block' : 'inline-block',
                '& .MuiBadge-badge': {
                    right: -10,
                    top: '0.5rem',
                    padding: '0 4px',
                }
            }}
        >
            {props.date && <Typography>{date.toLocaleDateString()}</Typography>}
            <Typography>
                {date.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                })}
            </Typography>
        </Badge>
    )
}

export function makeDate(props) {
    const cronArray = (props.cron || "").replace(/[cron()]/g, "").split(" ");
    const date = new Date()
    date.setUTCFullYear(props.start ? props.start.substring(0, 4) : new Date().getFullYear())
    date.setUTCMonth(props.start ? props.start.substring(5, 7) - 1 : new Date().getMonth())
    date.setUTCDate(props.start ? props.start.substring(8, 10) : new Date().getDate())
    date.setUTCHours(props.start ? props.start.substring(11, 13) : parseInt(cronArray[1], 10))
    date.setUTCMinutes(props.start ? props.start.substring(14, 16) : parseInt(cronArray[0], 10))
    date.setUTCSeconds(props.start ? props.start.substring(17, 19) : 0)
    const hourly = ((cronArray[1] || "").indexOf("/") > -1)

    return { date, hourly }
}

export function makeDatePrint(date, includeDate = false) {
    return (includeDate ? date.toLocaleDateString() + " " : "") + date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
    })
}
