import { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid';
import FormSelect from '../Form/FormSelect';

const SelectConnection = props => {
    const connections = useSelector(state => state.connections);
    const [value, setValue] = useState(props.value);

    const matchedConnections = props.limit && connections.data ?
        Object.entries(props.limit).map(([k, v]) => connections.data.filter(y => y[k] === v)).flat() :
        connections.data || []
    const options = matchedConnections.map(x => ({
        value: x.Id,
        system: x.SystemKey,
        label: x.Name,
        link: 'connections'
    }))

    useEffect(() => {
        setValue(props.value)
    }, [props])

    return (
        <Grid item xs={12}>
            <FormSelect
                name={props.name || "system"}
                label="Connection"
                options={options}
                value={value || ''}
                handler={props.handler}
            />
        </Grid>
    )
}

export default SelectConnection;