import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import SettingsIcon from '@mui/icons-material/Settings';

import { SystemConfig, SystemConfigFlat } from '../Config/SystemConfig';
import FormSelect from '../Form/FormSelect';
import Subtitle from "../Shared/Subtitle";


const JobConnections = props => {
    const connections = useSelector(state => state.connections)
    const [input, setInput] = useState(props.input);
    const [options, setOptions] = useState({});

    useEffect(() => {
        setInput(props.input)
        setOptions({
            connections: {
                source: makeOptionsConnections('source'),
                target: makeOptionsConnections('target')
            },
            actions: {
                source: makeOptionsActions('source'),
                target: makeOptionsActions('target')
            }
        })
    }, [props])

    const upper = type => (type.charAt(0).toUpperCase() + type.slice(1));

    const makeOptionsActions = node => {
        const system = props.input[node] || {}
        const types = system.system ? SystemConfig[system.system].types[system.type][node] : {}
        return Object.entries(types).map(([k, v]) => ({
            value: k,
            label: v.label,
            icon: v.icon || <SettingsIcon />
        }))
    }

    const makeOptionsConnections = (node) => {
        return connections.data.filter(x =>
            Object.keys(SystemConfig[x.SystemKey].types[x.SystemType]?.[node] || {}).length > 0
        ).map(x => ({
            value: x.Id,
            system: x.SystemKey,
            label: x.Name,
            type: x.SystemType,
            link: 'Connections'
        }))
    }

    const handleChange = (e, node) => {
        let id, action
        if (e.target.name === 'connection') {
            id = e.target.value
            action = ''
        } else if (e.target.name === "action") {
            id = input[node].id
            action = e.target.value
        }
        const c = connections.data.find(x => x.Id === id) || []
        const newInput = {
            ...input, ...{
                [node]: {
                    id: c.Id,
                    connectionName: c.Name,
                    system: c.SystemKey,
                    type: c.SystemType,
                    action: action
                }
            }
        }
        props.handler({ target: { name: 'Input', value: newInput } })
    }
    return (
        <Grid container className="Container">
            <Subtitle type="Connection" />
            {['source', 'target'].map((node, key) => (
                <Grid item xs={6} key={key} sx={{ pr: 1 }}>
                    <Grid item xs={12}>
                        <FormSelect
                            name="connection"
                            label={upper(node)}
                            options={options.connections?.[node]}
                            value={(input[node] || {}).id || ''}
                            handler={e => handleChange(e, node)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSelect
                            name="action"
                            label={`${upper(node)} Action`}
                            value={input[node].action || ''}
                            options={options.actions?.[node]}
                            handler={e => handleChange(e, node, 'action')}
                        />
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}

export default JobConnections;