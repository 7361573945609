import { WebStorageStateStore } from 'oidc-client-ts';

const region = "us-east-1";
const userPoolId = "rcrOJ99UB";// also update this in Okta if it changes
const identityPoolId = "5ed4beab-bada-41cc-80d0-014ae4b02e4e";
const apiId = "4gwvhkgw27"
const clientId = "4u4l8g5ld71p49od1kgmr9m02f"

const cognitoAuthConfig = {
    region: region,
    authority: `https://cognito-idp.${region}.amazonaws.com/${region}_${userPoolId}`,
    apiUrl: `https://${apiId}.execute-api.${region}.amazonaws.com/prod`,
    client_id: clientId,
    userPoolId: `${region}_${userPoolId}`,
    identityPoolId: `${region}:${identityPoolId}`,
    redirect_uri: window.location.origin,
    response_type: "code",
    scope: "aws.cognito.signin.user.admin email openid profile",
    userStore: new WebStorageStateStore({ store: window.localStorage })
};

export default cognitoAuthConfig;