import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '../Buttons/Button';
import AvatarSystem from '../Icons/AvatarSystem';

const Pod = props => {
    const theme = useTheme();
    const color = theme.palette[props?.color || 'offwhite'].main
    return (
        <Grid
            className="Pod"
            onClick={props.handler}
            sx={{
                background: theme.palette.offwhite.main,
                color: theme.palette[props.color || "neutral"].main,
                width: props.width || 'auto',
                border: `2px solid ${color}`,
                '&:hover': {
                    cursor: 'pointer',
                    // background: theme.palette.neutral.main
                }
            }}
        >
            {props.icon && <Grid>
                <AvatarSystem
                    icon={props.icon}
                    color={props.color || "neutral"}
                />
            </Grid>}
            {props.system && <Grid>
                <AvatarSystem
                    system={props.system}
                    color={props.color || "neutral"}
                />
            </Grid>}
            {props.icons && props.icons.map((icon, key) => (
                <Grid key={key}>
                    <AvatarSystem
                        icon={icon}
                        color={props.color || "neutral"}
                    />
                </Grid>
            ))}
            <Grid item xs sx={{ px: 0.5, overflow: 'hidden' }}>
                {props.children}
            </Grid>
            <Grid>
                {props.launch && <Button type="launch" small handler={props.launch} />}
                {props.refresh && <Button type="refresh" small handler={props.refresh} />}
                {props.delete && <Button type="delete" small handler={props.delete} />}
            </Grid>
        </Grid>
    )
}
export default Pod;