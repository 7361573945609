import Grid from '@mui/material/Grid';
import MatchIcons from "./MatchIcons";
import Button from '../Buttons/Button';
import ButtonConfig from '../Buttons/ButtonConfig';
import { useTheme } from '@mui/material/styles'

const MatchLabel = props => {
    const theme = useTheme()
    const active = props.matches.length > 0
    return (
        <Grid container>
            <Grid className="MatchButtonCount"
                sx={{ 
                    color: theme.palette[active ? 'primary' : 'neutral'].main 
                }}
            >
                {ButtonConfig[props.type].startIcon}
                {props.matches.length}
            </Grid>

            <MatchIcons type={props.type} matches={props.matches} />

            {props.handler && <Button small type="refresh" handler={props.handler} />}

            {props?.matches.length > 0 && (
                props.showMatches ?
                    <Button small type="arrowUp" handler={props.toggle} /> :
                    <Button small type="arrowDown" handler={props.toggle} />
            )}

        </Grid>
    )
}
export default MatchLabel;