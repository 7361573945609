import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import ButtonOrder from '../Buttons/ButtonOrder';
import Button from '../Buttons/Button';
import FormSelect from '../Form/FormSelect';
import FormTextField from '../Form/FormTextField';
import JobRecordRowParameters from './JobRecordRowParameters';
import JobRecordRowAttribute from './JobRecordRowAttribute';

const JobRecordRow = props => {
    const [item, setItem] = useState(props.item)
    const [width, setWidth] = useState(0)
    const [options, setOptions] = useState();

    const hasParams = props.node === "source"
    const colsName = 3;

    useEffect(() => {
        setWidth(12 / ((hasParams ? 1 : 0) + (props.attributes || []).length))
        setItem(props.item)
        setOptions(props.options)
    }, [props])

    return (
        <Grid container className="ContainerRow">
            <Grid item xs="auto">
                <ButtonOrder
                    order={item.column_order}
                    handler={props.handler}
                    name="record_order"
                />
            </Grid>
            <Grid item xs>
                <Grid container>
                    {/* Record Name */}
                    <Grid item xs={colsName}>
                        {hasParams ?
                            <FormSelect
                                name="object"
                                // label="Record"
                                value={item.object}
                                options={options?.names}
                                disableUnderline
                                handler={props.handler}
                            />
                            :
                            <FormTextField
                                name='object'
                                label="Record"
                                value={item.object || ''}
                                handler={props.handler}
                                header={props.header}
                                disableUnderline
                            />
                        }
                    </Grid>

                    {/* Record Parameters and Attributes */}
                    <Grid item xs={12 - colsName}>
                        <Grid container>
                            {hasParams &&
                                <Grid item xs={width}>
                                    {options?.params?.[item?.object] &&
                                        <JobRecordRowParameters
                                            width={width}
                                            item={item}
                                            options={options?.params?.[item?.object]}
                                            handler={props.handler}
                                        />}
                                </Grid>
                            }
                            {props?.attributes && props.attributes.map((attr, key) => (
                                <Grid item key={key} xs={width}>
                                    <JobRecordRowAttribute
                                        item={item}
                                        attr={attr}
                                        handler={props.handler}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
            <Grid item xs="auto">
                <Button small type="delete" handler={() => props.handler({ target: { name: "delete" } })} />
            </Grid>
        </Grid>
    )
}
export default JobRecordRow;