import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setSystems } from '../components/Cache/slices';

import Grid from '@mui/material/Grid';
import Header from '../components/Shared/Header';

import ControlPanel from '../components/Shared/ControlPanel';
import Navbar from './Navbar';
import RowResults from '../components/Rows/RowResults';

const Systems = () => {
    const systems = useSelector(state => state.systems);
    const sysconfig = useSelector(state => state.sysconfig);
    const [data, setData] = useState(systems.data || []);
    const [snackbar, setSnackbar] = useState();

    useEffect(() => {
        setData(systems.data || [])
        console.log(systems.data)
        console.log(sysconfig.data)
    }, [systems])

    // handlers
    const handleFilter = filter => {
        if (systems.data) {
            let newData = (systems.data || []).filter(x => (
                ((filter.search || '').length > 0 ? (x.Name || '').toLowerCase().includes(filter.search.toLowerCase()) : true) &&
                ((filter.system || []).length > 0 ? filter.system.some(system => x.SK.split('#')[0] === system) : true)
            ))
            setData(newData)
        }
    }

    return (
        <Navbar snackbar={snackbar}>
            <Grid container>
                <Header
                    type="System"
                    setData={setSystems}
                    data={data}
                />
                <ControlPanel
                    page="Systems"
                    handler={handleFilter}
                />
                <RowResults
                    type="systems"
                    loading={!systems.data}
                    data={data}
                    handlers={{}}
                    matches={{}}
                />
            </Grid>
        </Navbar>
    )
}

export default Systems;