import AvatarGroup from '@mui/material/AvatarGroup';
import Grid from '@mui/material/Grid';
import AvatarSystem from "../Icons/AvatarSystem";
import { SystemConfig } from '../Config/SystemConfig';
import Filters from '../Shared/Filters';

const MatchIcons = props => {
    const typeMap = {
        jobs: {
            system: x => SystemConfig?.[x.StateMachine.split('-').find(y => y != 'sqlserver')],
            icon: x => null
        },
        schemas: {
            system: x => SystemConfig?.[x?.SystemKey],
            icon: x => null
        },
        executions: {
            system: x => null,
            icon: x => Filters.Executions.Status.find(y => y.value === x.Status)
        }
    }
    const systems = [...new Set(props.matches.map(x => typeMap[props.type].system(x)))]
    const icons = props.matches.map(x => typeMap[props.type].icon(x))
    return (
        <AvatarGroup
            sx={{
                // display: 'inline-flex',
                '.MuiAvatar-root': { height: 24, width: 24, fontSize: '0.75rem', background: 'white', color: '#444' }
            }}
            max={5}
        >
            {
                systems.map((x, key) => {
                    return x && <AvatarSystem key={key} system={x} />
                })
            }
            {
                icons.map((x, key) => {
                    return x && <AvatarSystem key={key} icon={x.icon} color={x.color} />
                })
            }
        </AvatarGroup>
    )
}
export default MatchIcons