import { useState, useEffect } from "react";
import { SystemConfig } from '../Config/SystemConfig';
import Grid from '@mui/material/Grid';
import FormSelect from '../Form/FormSelect';

const SelectSystem = props => {
    const [value, setValue] = useState(props.value);
    const options = Object.entries(SystemConfig).map(([name, item]) => ({
        value: name,
        system: name,
        label: item.label
    }))
    useEffect(()=>{
        setValue(props.value)
    }, [props])
    return (
        <Grid container>
            <Grid item xs={12}>
                <FormSelect
                    name={props.name || "system"}
                    label="System"
                    options={options}
                    value={value || ''}
                    handler={props.handler}
                />
            </Grid>
        </Grid>
    )
}

export default SelectSystem;