import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Box, IconButton, Tab, Tabs, Badge, CircularProgress, Link, Button } from '@mui/material';
import ReportTable from './ReportTable';
import FormSQLEditor from '../Form/FormSQLEditor';
import BarChart from '../Chart/Bar';
import TableRowsIcon from '@mui/icons-material/TableRows';
import CodeIcon from '@mui/icons-material/Code';
import BarChartIcon from '@mui/icons-material/BarChart';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink, CSVDownload } from "react-csv";

const QueryTabs = props => {
    // const [tab, setTab] = useState(
    //     (window.location.hash === '#data') ? 1 : 0
    // );
    const [tab, setTab] = useState(0);
    const [query, setQuery] = useState(props.query);
    const [tables, setTables] = useState(props.tables);
    // const [result, setResult] = useState(props.result || []);
    const [result, setResult] = useState(props?.result?.data || []);
    const [url, setUrl] = useState(props?.result?.url || []);


    useEffect(() => {
        setQuery(props.query)
        setTables(props.tables)
        // props?.result && setResult(props.result)
        // props?.result && setResult(props.result.data)
        props?.result && setUrl(props.result.url)
        props?.result?.url && handleFullData(props.result.url)
    }, [props]);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const LabelData = () => {
        return (
            <Badge
                badgeContent={result ? result.length : <CircularProgress size="1rem" />}
                color={result && result.length > 0 ? "success" : "white"}
                max={Infinity}
            >
                <TableRowsIcon />
            </Badge>
        )
    }

    const LabelCSV = () => (
        <CSVLink
            filename={`${(props.id || "").replace(/[^A-z0-9]/gi, '')}.csv`}
            data={result}
        >
            <Tab label={<DownloadIcon />} />
        </CSVLink>
    )
    const handleFullData = async (url) => {
        // const response = await fetch(url, {mode: "no-cors"}).then(
            
        // )
        // await fetch(url).then(response => console.log(response.text()))
        // .then(body => console.log(body))
        const response = await fetch(url)
        console.log(response)
        const data = await response.json()
        console.log(data[0])
        setResult(data)
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs>
                {url.length > 0 && <Button onClick={handleFullData}>DATA</Button>}
                {tab == 0 &&
                    <FormSQLEditor
                        query={query}
                        tables={tables}
                        handler={props.handler}
                    />
                }
                {tab == 1 &&
                    <Grid container>
                        <Grid item xs sx={{ width: 0 }}>
                            <ReportTable
                                data={result.slice(0, 100)}
                            />
                        </Grid>
                    </Grid>
                }
                {tab == 2 &&
                    <Grid container>
                        <Grid item xs sx={{ width: 0 }}>
                            <BarChart
                                data={result}
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid item style={{ display: 'flex' }}>
                <Tabs
                    orientation="vertical"
                    indicatorColor="primary"
                    value={tab}
                    onChange={handleTabChange}
                    sx={{ '& .MuiBadge-badge': { mr: -1 } }}
                >
                    <Tab label={<CodeIcon />} />
                    <Tab label={<LabelData />} />
                    {/* <Tab label={<BarChartIcon />} /> */}
                    {/* {result.length > 0 && <LabelCSV />} */}
                    {/* {result.length > 0 && <Link
                        href={url}
                    >
                        <Tab label={<DownloadIcon />} />
                    </Link>} */}
                </Tabs>

            </Grid>

        </Grid>
    );
}

export default QueryTabs;