import React, { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { Grid, Link, Avatar } from '@mui/material';

import FormTextField from '../Form/FormTextField';
import Filters from './Filters';
import AvatarSystem from '../Icons/AvatarSystem';

const Title = props => {
    // const hasType = () => (Filters[props.page].type ? Filters[props.page].type.find(x => x.value === props.type) || {} : {})
    // const [type, setType] = useState(hasType())
    const [title, setTitle] = useState(props.value)
    const theme = useTheme();
    const avatars = props?.avatars || []
    const icons = props?.icons || []

    useEffect(() => {
        // setType(hasType())
        setTitle(props.label)
    }, [props])

    const handleTitleChange = e => {
        setTitle(e.target.value)
        props.handler(e)
    }

    return (
        <Grid item xs className="Title">
            <Grid container>
                <Grid item>
                    {avatars.map((avatar, key) => (
                        <Avatar
                            key={key}
                            src={avatar}
                        />
                    ))}
                    {icons.map((icon, key) => (
                        <Avatar
                            key={key}
                            sx={{
                                color: theme.palette.primary.main
                            }}
                        >
                            {icon.icon}
                        </Avatar>
                    ))}
                </Grid>
                <Grid item xs className={props.row ? "TitleRow" : ""}>
                    {props.href ?
                        <Link href={props.href} underline="hover" target="_blank">
                            {title}
                        </Link>
                        :
                        <FormTextField
                            value={title}
                            name="Name"
                            placeholder="Name"
                            handler={handleTitleChange}
                            disabled={props.disabled}
                            disableUnderline
                        />
                    }
                </Grid>
            </Grid>

        </Grid>
    )
}
export default Title;