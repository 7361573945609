import cognitoAuthConfig from "../../config";

export const api = async (user, method, resource, endpoint, body, callbacks) => {
    console.log(method, resource, endpoint)
    const url = cognitoAuthConfig.apiUrl
    try {
        const response = await fetch(`${url}/${user.org}/${resource}/${endpoint === "new" ? "" : endpoint}`, {
            method: method,
            headers: {
                Authorization: `Bearer ${user.id_token}`,
            },
            body: method !== "GET" ? JSON.stringify(body) : null
        });
        const data = await response.json();
        if (response.ok) {
            // execute callbacks
            for await (const fn of callbacks) {
                await fn(data)
            }
            // redirect to new item page or parent page if necessary
            (endpoint === "new" || method === "DELETE") && setTimeout(function () {
                window.location.href = data.Id ? `/${resource}/${data.Id}` : `/${resource}`
            }, 1000)

            // otherwise return the appropriate snackbar response or null
            switch (method) {
                case "PUT": return { severity: "success", message: "Changes Saved!" }
                default: return
            }
        } else {
            callbacks.forEach(fn => fn([]))
            return { severity: "error", message: data }
        }
    } catch (error) {
        console.error(error)
        return { severity: "error", message: error.toString() }
    }
}