import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Subtitle from '../Shared/Subtitle';

const JobDescription = (props) => {
    let css = {
        '& .MuiInput-root': { color: 'gray' },
        '& .MuiInput-underline:before': { borderBottomColor: 'transparent' },
        '& .MuiInput-underline:after': { borderBottomColor: 'gray' },
    }
    return (
        <Grid container className="Container">
            <Subtitle type="Note" />
            <TextField
                fullWidth
                multiline
                variant='standard'
                name='Description'
                placeholder='Description'
                defaultValue={props.value}
                onChange={props.handler}
                sx={css}
            />
        </Grid>
    )
}
export default JobDescription