import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setQueries } from '../components/Cache/slices';

import Grid from '@mui/material/Grid';

import ControlPanel from '../components/Shared/ControlPanel';
import Header from '../components/Shared/Header';
import RowQuery from '../components/Rows/RowQuery';
import RowResults from '../components/Rows/RowResults';

import Loading from '../components/Shared/Loading';
import Navbar from './Navbar';
import FormSelectSchemas from '../components/Form/FormSelectSchemas';

const queryJobs = (queries, jobs) => {
    let data = {}
    queries.data.forEach(x =>
        data[x.Id] = jobs.data.filter(y =>
            y.Input &&
            y.Input.records.some(z => z.queryId === x.Id)
        ))
    return data
}

const querySchemas = (queries, schemas) => {
    let data = {}
    queries.data.forEach(x =>
        data[x.Id] = [
            ...(data?.[x.Id] || []),
            ...(x.tables || []).map(y => (schemas.data || []).find(z =>
                z.SystemKey === y.split('.').splice(-2)[0] && z.Name == y.split('.').splice(-2)[1]
            ))
        ]
    )
    return data
}

const Queries = () => {
    const queries = useSelector(state => state.queries);
    const jobs = useSelector(state => state.jobs);
    const schemas = useSelector(state => state.schemas);

    const [data, setData] = useState(queries.data || [])
    const [flat, setFlat] = useState({});
    const [snackbar, setSnackbar] = useState();

    const [systems, setSystems] = useState([])

    useEffect(() => {
        setData(queries.data || [])
        queries.data && jobs.data && schemas.data && setFlat({
            jobs: queryJobs(queries, jobs),
            schemas: querySchemas(queries, schemas)
        })
    }, [queries, jobs, schemas])


    const handleFilter = filter => {
        if (queries.data) {
            const newData = queries.data.filter(x => (
                ((filter.search || '').length > 0 ? x.Name.toLowerCase().includes(filter.search.toLowerCase()) : true) &&
                ((filter.system || []).length > 0 ? filter.system.some(system => [...new Set((x?.tables || []).map(y => y.split('.')[0]))].includes(system)) : true) &&
                ((filter.schema || '').length > 0 ? flat?.schemas[x.Id].some(y=>y?.Name===filter.schema) : true)
            ))
            setData(newData)
            setSystems(filter.system || [])
        }
    }
    // console.log([...new Set(schemas.data.map(x=>x.Name))])
    return (
        <Navbar snackbar={snackbar}>
            <Grid container>
                <Header
                    type="Query"
                    setData={setQueries}
                    data={data}
                />
                <ControlPanel
                    page="Queries"
                    handler={handleFilter}
                    selects={[
                        {type: "schema", label: "Schema", options: [...new Set(schemas.data.map(x=>x.Name))]}
                    ]}
                />

                {/* Results */}
                {!queries.data
                    ?
                    <Loading />
                    :
                    <Grid container>
                        <RowResults
                            type="queries"
                            loading={!queries.data}
                            data={data}
                            matches={{
                                jobs: x => flat?.jobs?.[x] || [],
                                schemas: x => flat?.schemas?.[x] || []
                            }}
                        />
                    </Grid>
                }
            </Grid>
        </Navbar>
    );
}

export default Queries;