import { useState, useEffect } from "react";
import { useUser } from '../components/Auth/AuthContext';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setQueries } from '../components/Cache/slices';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import { updateCache } from '../components/Shared/Functions';

import Grid from '@mui/material/Grid';
import LinearProgress from "@mui/material/LinearProgress";

import Navbar from "./Navbar";
import ActionButtons from '../components/Buttons/ActionButtons';
import Title from '../components/Shared/Title';
import SelectConnection from '../components/Select/SelectConnection';
import QueryTabs from '../components/Query/QueryTabs';
import { matchTables } from '../components/Shared/Functions';
import Modal from '../components/Shared/Modal';

import { api } from '../components/Functions/API';


const Query = () => {
    const dispatch = useDispatch();
    const user = useUser();
    const params = useParams();
    const createNew = (window.location.pathname.split('/').pop() === "new")

    const queries = useSelector(state => state.queries);
    const [data, setData] = useState();
    const [snackbar, setSnackbar] = useState(null);
    const [modal, setModal] = useState();

    // Query-specific
    const [query, setQuery] = useState("");
    const [result, setResult] = useState();
    const [tables, setTables] = useState([]);

    useEffect(() => {
        user.org && getData()
    }, [user])

    const getData = async () => {
        if (createNew) { setData({}); return }
        const response = await api(user, 'GET', 'queries', params.id, null, [
            x => setData(x),
            x => setQuery(x.query),
            x => setTables(matchTables(x.query))
        ])
        response && setSnackbar(response) 
    }
    const saveData = async () => {
        setSnackbar({ severity: 'info', message: "Saving Changes" }) 
        const { PK, SK, LastModified, results, ...body } = { ...data, ...{ Type: "query" } }
        const response = await api(user, 'PUT', 'queries', params.id, body, [
            x => setData(x),
            x => updateCache(dispatch, setQueries, queries, x)
        ])
        response && setSnackbar(response) 
    }
    const deleteData = async () => {
        setSnackbar({ severity: 'info', message: `Deleting ${data.Name}` }) 
        const response = await api(user, 'DELETE', 'queries', params.id, null, [
            x => updateCache(dispatch, setQueries, queries, x, params.id)
        ])
        response && setSnackbar(response) 
    }
    const run = async () => {
        setSnackbar({ severity: "info", message: "Running Query", timer: true })
        const response = await api(user, "POST", "queries", params.id, data, [
            x => setResult(x)
        ])
        response && setSnackbar(response) 
    }
    const handleChange = e => { 
        const isQuery = e.target.name === "query"
        const tables = isQuery ? matchTables(e.target.value) : data.tables
        setData({ ...data, ...{ [e.target.name]: e.target.value }, ...{ tables: tables } })
        isQuery && setQuery(e.target.value);
        isQuery && setTables(tables);
    }
    return (
        <Navbar snackbar={snackbar}>
            <Modal data={modal} close={() => setModal()} delete={deleteData} />
            {data ?
                <Grid container>
                    <Title
                        page="Queries"
                        value={data.Name}
                        handler={handleChange}
                        icons={[{ icon: <JoinLeftIcon /> }]}
                        systems={[]}
                    />
                    <ActionButtons
                        createNew={createNew}
                        type="query"
                        delete={() => setModal(`Are you sure you want to delete ${data.Name}?`)}
                        query={run}
                        save={saveData}
                    />
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <SelectConnection
                            name="connection"
                            value={data.connection || ''}
                            handler={handleChange}
                            limit={{ SystemType: 'sqlserver' }}
                        />
                    </Grid>
                    <QueryTabs
                        id={params.id}
                        query={query}
                        result={result}
                        tables={tables}
                        handler={handleChange}
                    />
                </Grid>
                :
                <Grid item xs={12}><LinearProgress /></Grid>
            }
        </Navbar>
    )
}

export default Query;
