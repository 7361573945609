import React from 'react';
import { Grid, Typography, Avatar, Link, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import Time from '../Shared/Time';
import { useTheme } from '@mui/material/styles';


const ExecutionBlock = props => {
    const theme = useTheme();
    const href = `/executions/${props.item?.StateMachine}:${props.item?.ExecutionID}`
    const status = {
        Succeeded: {
            icon: <CheckCircleIcon />,
            color: theme.palette.success.main
        },
        Failed: {
            icon: <CancelIcon />,
            color: theme.palette.error.main
        },
        Aborted: {
            icon: <HelpIcon />,
            color: theme.palette.neutral.main
        }
    }

    return (
        <Grid container
            sx={{
                alignItems: 'center',
                '& .MuiTypography-root': { lineHeight: '1rem' }
            }}
        >
            <Grid item xs={2}>
                <Time start={props.item.StartTime} date={props.date} />
            </Grid>
            <Grid item xs={9}
                sx={{ 
                    // ml: (props.item.Buffer * 100) + '%',
                    '& .MuiGrid-container': { alignItems: 'center' } 
                }}
            >
                <Link
                    href={props.href && href}
                    underline="none"
                    target="_blank"
                    rel="noopener"
                    sx={{ display: 'flex', alignItems: 'center', width: 1 }}
                >
                    <Box
                        // onClick={() => window.open(href, '_blank', 'noopener,noreferrer')}
                        sx={{
                            minWidth: '0.75rem',
                            width: (props.item.Duration / props.maxWidth),
                            borderRadius: '1rem 0 0 1rem',
                            // background: color,
                            background: status[props.item.Status].color,
                            height: '1.5rem',
                            ml: (props.item.Buffer * 100) + '%'
                        }} />
                    <Avatar sx={{
                        // color: color,
                        color: status[props.item.Status].color,
                        background: 'white',
                        width: 'auto',
                        height: 'auto',
                        ml: -1.75,
                        '& .MuiSvgIcon-root': {
                            height: '1.75rem',
                            width: '1.75rem'
                        }
                    }}>
                        {/* {props.item.Status === 'Succeeded' ? <CheckCircleIcon /> : <CancelIcon />} */}
                        {status[props.item.Status].icon || <HelpIcon />}
                    </Avatar>

                    <Typography
                        // color={color}
                        color={status[props.item.Status].color}
                    >
                        {new Date(props.item.Duration * 1000).toISOString().slice(14, 19)}
                    </Typography>
                </Link>
                {props.item?.error && <Typography sx={{ color: theme.palette.error.main, fontSize: '0.75rem' }}>{props.item?.error}</Typography>}
            </Grid>
        </Grid>
    )
}
export default React.memo(ExecutionBlock);