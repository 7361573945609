import { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { setConnections } from '../components/Cache/slices'

import Grid from '@mui/material/Grid';

import Navbar from "./Navbar";
import Header from '../components/Shared/Header';
import ControlPanel from '../components/Shared/ControlPanel';
import RowResults from '../components/Rows/RowResults';

const connectionJobs = (connections, jobs) => {
    let connectionJobs = {}
    connections.data.forEach(x =>
        connectionJobs[x.Id] = jobs.data.filter(y =>
            y.Input &&
            y.Input.source.id === x.Id || y.Input.target.id === x.Id)
    )
    return connectionJobs
}

const Connections = () => {
    const jobs = useSelector(state => state.jobs);
    const connections = useSelector(state => state.connections);

    const [data, setData] = useState(connections?.data || []);
    const [flat, setFlat] = useState({});
    const [snackbar, setSnackbar] = useState();

    useEffect(() => {
        setData(connections?.data || [])
        connections.data && jobs.data && setFlat({
            jobs: connectionJobs(connections, jobs)
        })
    }, [connections, jobs])

    const handleFilter = filter => {
        if (connections.data) {
            let newData = connections.data.filter(x => (
                ((filter.search || '').length > 0 ? x.Name.toLowerCase().includes(filter.search.toLowerCase()) : true) &&
                ((filter.type || '').length > 0 ? x.SystemType === filter.type : true) &&
                ((filter.system || []).length > 0 ? filter.system.includes(x.SystemKey) : true)
            ))
            setData(newData)
        }
    }
    console.log(connections)
    return (
        <Navbar snackbar={snackbar}>
            <Grid container>
                <Header
                    type="Connection"
                    setData={setConnections}
                    data={data}
                />

                <ControlPanel
                    page="Connections"
                    handler={handleFilter}
                />

                {/* Results */}
                <RowResults
                    type="connections"
                    loading={!connections.data}
                    data={data}
                    matches={{
                        jobs: x => flat?.jobs?.[x] || []
                    }}
                />
            </Grid>
        </Navbar>
    );
}

export default Connections;