import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ButtonOrder = props => {
    return (
        <ButtonGroup size="small" color="neutral" variant="outlined"
            sx={{
                height: 32,
                p: 0.5,
                // '& .MuiButton-root': { p: 0.25, minWidth: 40, borderRadius: '1rem' }
            }} 
        >
            <Button
                size="small"
                // onClick={() => props.handler(-1)}
                onClick={() => props.handler({ target: { name: props.name, value: -1 } })}
            >
                <ArrowDropUpIcon />
            </Button>
            <Button
                size="small"
                // onClick={() => props.handler(1)}
                onClick={() => props.handler({ target: { name: props.name, value: 1 } })}
            >
                <ArrowDropDownIcon />
            </Button>
        </ButtonGroup>
    )
}
export default ButtonOrder;