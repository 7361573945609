import React, { useState, useEffect } from "react";
import { useUser } from '../Auth/AuthContext';
import { useSelector, useDispatch } from 'react-redux';

import { store } from '../Cache/store';
import {
    setConnections,
    setExecutions,
    setJobs,
    setQueries,
    setSchemas,
    setSysconfig,
    setSystems,
    setUsers
} from '../Cache/slices';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from "@mui/material/CircularProgress";

import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import { sortResponse } from '../Shared/Functions';
import Pod from "../Shared/Pod";

import cognitoAuthConfig from "../../config";
import { api } from '../Functions/API';

export default function CacheMenu(props) {
    const user = useUser()
    const dispatch = useDispatch()
    dayjs.extend(relativeTime);
    dayjs.extend(updateLocale);
    dayjs.updateLocale('en', {
        relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: "%ds",
            m: "1m",
            mm: "%dm",
            h: "1h",
            hh: "%dh",
            d: "1d",
            dd: "%dd",
            M: "1m",
            MM: "%dm",
            y: "1y",
            yy: "%dy"
        }
    })

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const state = store.getState()

    const connections = useSelector(state => state.connections);
    const executions = useSelector(state => state.executions);
    const jobs = useSelector(state => state.jobs);
    const queries = useSelector(state => state.queries);
    const schemas = useSelector(state => state.schemas);
    const sysconfig = useSelector(state => state.sysconfig);
    const systems = useSelector(state => state.systems);
    const users = useSelector(state => state.users);


    const UserPoolId = cognitoAuthConfig.userPoolId

    const [fetching, setFetching] = useState({});

    const fn = {
        connections: setConnections,
        executions: setExecutions,
        jobs: setJobs,
        queries: setQueries,
        schemas: setSchemas,
        sysconfig: setSysconfig,
        systems: setSystems,
        users: setUsers
    }

    const handleFetching = () => {
        setFetching({
            connections: !connections.data,
            executions: !executions.data,
            jobs: !jobs.data,
            queries: !queries.data,
            schemas: !schemas.data,
            sysconfig: !sysconfig.data,
            systems: !systems.data,
            users: !users.data
        })
    }

    useEffect(() => {
        if (user.org) {
            handleFetching()
            !connections.data && !fetching.connections && get('connections');
            !executions.data && !fetching.executions && get('executions');
            !jobs.data && !fetching.jobs && get('jobs');
            !queries.data && !fetching.queries && get('queries');
            !schemas.data && !fetching.schemas && get('schemas');
            !sysconfig.data && !fetching.sysconfig && get('sysconfig');
            !systems.data && !fetching.systems && get('systems');
            !users.data && !fetching.users && get('users');
        }
    }, [user, connections, executions, jobs, queries, schemas, sysconfig, systems, users]);

    const get = async resource => {
        const callbacks = {
            connections: x => dispatch(setConnections(sortResponse(x, "Name", "alpha"))),
            executions: x => dispatch(setExecutions(x.sort((a, b) => { return (b.SK) - (a.SK) }))),
            jobs: x => dispatch(setJobs(sortResponse(x, "Name", "alpha"))),
            queries: x => dispatch(setQueries(sortResponse(x, "Name", "alpha").map(y => ({ ...y, ...{ results: [] } })))),
            schemas: x => dispatch(setSchemas(sortResponse(x, "Name", "alpha"))),
            sysconfig: x => dispatch(setSysconfig(sortResponse(x, "Name", "alpha"))),
            systems: x => dispatch(setSystems(sortResponse(x, "Name", "alpha"))),
            users: x => dispatch(setUsers(x.map(y => ({ ...y, Username: y.Email })).filter(y => y.UserPoolId === UserPoolId)))
        }
        const response = await api(user, "GET", resource, "", null, [callbacks[resource]])
        response && props.handler(response);
    }


    return (
        user &&
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Cache Status">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {Object.keys(fn).map((type, key) => {
                    const item = state[type] || {}
                    const color = item.lastUpdated && (dayjs().diff(item.lastUpdated, 'hour'))

                    return (
                        <MenuItem key={key} sx={{ '& .MuiGrid-root': { px: 0.5, justifyContent: 'center' } }}>
                            <Grid container className="ContainerRow">
                                <Grid>
                                    <IconButton
                                        onClick={() => dispatch(fn[type](null))}
                                    // onClick={() => get(type)}
                                    >
                                        <Badge
                                            badgeContent={item.lastUpdated && dayjs(item.lastUpdated).fromNow(true)}
                                            color={color > 24 ? "error" : color > 12 ? "secondary" : "offwhite"}
                                            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                                        >
                                            <RefreshIcon />
                                        </Badge>
                                    </IconButton>
                                </Grid>
                                <Grid item xs>
                                    {type}
                                </Grid>
                                <Grid sx={{ minWidth: 48 }}>
                                    {item.data === null ?
                                        <CircularProgress size={20} onClick={() => dispatch(fn[type]([]))} /> :
                                        <Pod>{(item?.data || []).length}</Pod>
                                    }
                                </Grid>
                            </Grid>
                        </MenuItem>
                    )
                }
                )}
            </Menu>
        </React.Fragment>
    );
}