import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../components/Auth/AuthContext';
import { useSelector, useDispatch } from 'react-redux'
import { setConnections } from '../components/Cache/slices';
import { updateCache, makeConnectionOptions } from '../components/Shared/Functions';

import { SystemConfig } from '../components/Config/SystemConfig';

import { Grid, LinearProgress } from '@mui/material';
import Title from '../components/Shared/Title';

import FormSelect from '../components/Form/FormSelect';
import FormTextField from '../components/Form/FormTextField';
import FormApiKeys from '../components/Form/FormApiKeys';
import ActionButtons from '../components/Buttons/ActionButtons';
import Subtitle from '../components/Shared/Subtitle';
import Modal from '../components/Shared/Modal';

import { GetSecureValue, PutSecureValue, DeleteSecureValue } from '../components/Auth/SSM';
import { v4 as uuidv4 } from 'uuid';
import Navbar from './Navbar';
import { api } from '../components/Functions/API';

const Connection = () => {
    const dispatch = useDispatch();
    const user = useUser();
    const params = useParams();
    const createNew = (window.location.pathname.split('/').pop() === "new")
    
    const connections = useSelector(state => state.connections);
    const [data, setData] = useState();
    const [snackbar, setSnackbar] = useState(null);
    const [modal, setModal] = useState();

    // Connection-specific
    const [credentials, setCredentials] = useState();
    const [secrets, setSecrets] = useState({});

    useEffect(() => {
        user.org && getData()
    }, [user])

    const getData = async () => {
        if (createNew) { setData({}); return }
        const response = await api(user, 'GET', 'connections', params.id, null, [
            x => setData(x)
        ])
        response && setSnackbar(response)
    }

    const saveData = async () => {
        setSnackbar({ severity: 'info', message: "Saving Changes" })
        const { PK, SK, LastModified, ...body } = { ...data, ...{ Type: "connection" } }
        const response = await api(user, 'PUT', 'connections', params.id, body, [
            x => setData(x),
            async () => await saveSecures(),
            x => updateCache(dispatch, setConnections, connections, x)
        ])
        response && setSnackbar(response)
    }
    const deleteData = async () => {
        setSnackbar({ severity: 'info', message: `Deleting ${data.Name}` })
        const response = await api(user, 'DELETE', 'connections', params.id, null, [
            async () => await deleteSecures(),
            x => updateCache(dispatch, setConnections, connections, x, params.id)
        ])
        response && setSnackbar(response)
    }

    const handleChange = (e, i) => {
        const newConn = { ...data }
        let value = e.target.value

        if (e.target.name === "SystemKey") {
            newConn.SystemType = null
        }

        // credential change
        if (credentials && credentials.map(x => x.name).includes(e.target.name)) {
            // update secret and set value to existing value or generate uuid if new connection
            if (Object.keys(secrets).includes(e.target.name)) {
                setSecrets({ ...secrets, ...{ [e.target.name]: e.target.value } })
                value = (newConn.Credentials || {})[e.target.name] || makeUuid()
            }
            newConn.Credentials = {
                ...(newConn.Credentials || {}),
                ...{ [e.target.name]: value }
            }
            // non-credential change (Name, Key, Type)
        } else {
            newConn[e.target.name] = e.target.value
        }
        // console.log(secrets)

        // standard connection values
        newConn.Type = 'connection'
        setData(newConn)
    }

    // Connections-specific

    useEffect(() => {
        makeCredentials(data);
    }, [data && data.SystemType]);

    const getSecrets = async (key, value) => {
        let newSecrets = {}
        if (key === 'apikeys') {
            for await (const [k, v] of Object.entries(value)) {
                newSecrets[v] = await GetSecureValue(user, v)
            }
            return newSecrets
        } else {
            for await (const [k, v] of Object.entries({ [key]: value })) {
                newSecrets[k] = await GetSecureValue(user, v)
            }
            return newSecrets
        }
    }

    const saveSecures = async () => {
        for await (const [k, v] of Object.entries(secrets)) {
            await PutSecureValue(user, data.Credentials[k], v || ' ')
        }
    }
    const deleteSecures = async () => {
        for await (const [k, v] of Object.entries(secrets)) {
            await DeleteSecureValue(user, data.Credentials[k])
        }
    }

    const makeCredentials = async () => {
        if (data === undefined) { return }
        else if (data.SystemType === '') {
            setCredentials([])
            setData({ ...data, ...{ Credentials: {} } })
        } else {
            const creds = (data.SystemKey && Object.keys(SystemConfig[data.SystemKey].types).includes(data.SystemType)) ?
                Object.values(SystemConfig[data.SystemKey].types[data.SystemType].connection).map(x => ({
                    name: x.inputconfig.name,
                    label: x.inputconfig.label,
                    type: x.type
                }))
                :
                []
            let newSecrets = creds.filter(x => ['password', 'apikeys'].includes(x.type)).map(x => x.name).reduce((a, b) => (a[b] = '', a), {})
            if (!createNew) {
                for (let i in creds) {
                    creds[i].value = data.Credentials[creds[i].name] || ''
                    if (['password', 'apikeys'].includes(creds[i].type)) {
                        creds[i].value = (createNew || creds[i].value === "") ? makeUuid() : creds[i].value
                        newSecrets = {
                            ...newSecrets,
                            ...await getSecrets(creds[i].name, creds[i].value)
                        }
                    }
                }
            }
            const newData = { ...data, ...{ Credentials: creds.reduce((a, b) => { a[b.name] = b.value; return a }, {}) } }
            setSecrets(newSecrets)
            setCredentials(creds)
            setData(newData)
        }
    }

    const makeUuid = () => {
        return `/${user.org}/${data.SystemKey}/${data.SystemType}/${uuidv4()}`
    }

    const handleAddApiKey = () => {
        const newUuid = makeUuid()
        setSecrets({ ...secrets, ...{ [newUuid]: '' } })

        let newCredentials = [...credentials]
        let apikeys = newCredentials.splice(newCredentials.findIndex(x => x.name === "apikeys"), 1)
        apikeys[0].value = { ...apikeys[0].value, ...{ '': newUuid } }
        setCredentials([...newCredentials, ...apikeys])
    }

    return (
        <Navbar snackbar={snackbar}>
            <Modal data={modal} close={() => setModal()} delete={deleteData} />
            {data ?
                <Grid container>
                    <Title
                        page="Connections"
                        value={data.Name}
                        avatars={[{ system: SystemConfig[data.SystemKey] }]}
                        type={data.SystemType}
                        handler={handleChange}
                    />
                    <ActionButtons
                        createNew={createNew}
                        // delete={handleDelete}
                        delete={() => setModal(`Are you sure you want to delete ${data.Name}?`)}
                        save={saveData}
                    />
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6} sx={{ pr: 0.5 }}>
                                <Grid container className="Container">
                                    <Subtitle type="Type" />
                                    <FormSelect
                                        name={"SystemKey"}
                                        label="System"
                                        options={Object.entries(SystemConfig).map(([name, item]) => ({
                                            value: name,
                                            system: name,
                                            label: item.label
                                        }))}
                                        value={data.SystemKey || ''}
                                        handler={handleChange}
                                    />
                                    <FormSelect
                                        name="SystemType"
                                        label="Connection Type"
                                        options={makeConnectionOptions(data.SystemKey || '')}
                                        value={data.SystemType || ''}
                                        handler={handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sx={{ pl: 0.5 }}>
                                {/* Credentials */}
                                <Grid container className="Container">
                                    <Subtitle type="Credential" />
                                    {credentials && credentials.length > 0 ?
                                        credentials.map((field, i) => {
                                            return (
                                                <Grid item xs={12} key={i}>
                                                    {field.type === 'apikeys' ?
                                                        <FormApiKeys
                                                            {...field}
                                                            label="API Keys"
                                                            keys={field.value}
                                                            secrets={secrets}
                                                            handler={(e) => handleChange(e, i)}
                                                            add={handleAddApiKey}
                                                        /> :
                                                        <FormTextField
                                                            {...field}
                                                            value={field.type === 'password' ? secrets[field.name] : field.value}
                                                            handler={(e) => handleChange(e, i)}
                                                        />
                                                    }
                                                </Grid>
                                            )
                                        })
                                        :
                                        createNew ? null : <Grid item xs={12}><LinearProgress /></Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>





                </Grid >
                :
                <Grid item xs={12}><LinearProgress /></Grid>
            }
        </Navbar>
    )
}

export default Connection;