import { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import TablePagination from "@mui/material/TablePagination";
import Loading from '../Shared/Loading';
import RowConnection from "./RowConnection";
import RowExecution from "./RowExecution";
import RowJob from "./RowJob";
import RowQuery from "./RowQuery";
import RowSchema from "./RowSchema";
import RowSystem from "./RowSystem";
import RowUser from "./RowUser";
import { X } from "@mui/icons-material";

const RowResults = props => {
    const sysconfig = useSelector(state => state.sysconfig);
    const logos = sysconfig.data && sysconfig.data.map(x => ({ [x.SystemKey]: x.Logo })).reduce((a, b) => ({ ...a, ...b }), {})
    const [data, setData] = useState(props.data)
    const [page, setPage] = useState(0)
    const [perPage, setPerPage] = useState(10)

    useEffect(() => {
        setData(props.data)
        setPage(0)
    }, [props.data])

    const handlePerPage = e => {
        setPerPage(parseInt(e.target.value, 10));
        setPage(0);
    }

    return props.loading
        ?
        <Loading />
        :
        <Grid container>
            {data &&
                <Grid item xs={12}>
                    <TablePagination
                        component="div"
                        count={data?.length}
                        page={page}
                        onPageChange={(e, v) => setPage(v)}
                        rowsPerPage={perPage}
                        onRowsPerPageChange={handlePerPage}
                    />
                    {data.slice(page * perPage, (page + 1) * perPage).map((item, key) => {
                        // stupid execution-jobId workaround
                        const exId = props.type === 'executions' ? (item.GSI1PK || '').substring(4) : item.Id

                        const matches = {
                            jobs: props?.matches?.jobs ? props.matches.jobs(item.Id) : [],
                            executions: props?.matches?.executions ? props.matches.executions(exId) : [],
                            schemas: props?.matches?.schemas ? props.matches.schemas(item.Id) : []
                        }
                        switch (props.type) {
                            case 'connections':
                                return (
                                    <RowConnection
                                        key={key}
                                        item={item}
                                        avatars={[logos?.[item.SystemKey]]}
                                        matches={matches}
                                    />
                                )
                            case 'executions':
                                return (
                                    <RowExecution
                                        key={key}
                                        item={item}
                                        matches={matches}
                                        maxWidth={props.maxWidth}
                                    />
                                )
                            case 'jobs':
                                return (
                                    <RowJob
                                        key={key}
                                        item={item}
                                        matches={matches}
                                        {...props.handlers}
                                    />
                                )
                            case 'queries':
                                return (
                                    <RowQuery
                                        key={key}
                                        matchKey={key}
                                        item={item}
                                        matches={matches}
                                    />
                                )
                            case 'schemas':
                                return (
                                    <RowSchema
                                        key={key}
                                        item={item}
                                        matches={matches}
                                        {...props.handlers}
                                    />
                                )
                            case 'systems':
                                return (
                                    <RowSystem
                                        key={key}
                                        item={item}
                                        avatars={[logos?.[item.SystemKey]]}
                                        {...props.handlers}
                                    />
                                )
                            default:
                                return null
                        }
                    })}
                </Grid>
            }
        </Grid>
}
export default RowResults