import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSystems } from '../components/Cache/slices';
import { useUser } from '../components/Auth/AuthContext';
import { SystemConfig } from '../components/Config/SystemConfig';

import Filters from '../components/Shared/Filters';

import { updateCache } from '../components/Shared/Functions';
import ActionButtons from '../components/Buttons/ActionButtons';
import Title from '../components/Shared/Title';
import Subtitle from '../components/Shared/Subtitle';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Pod from '../components/Shared/Pod';
import FormSelect from '../components/Form/FormSelect';
import FormSelectSystems from '../components/Form/FormSelectSystems';
import SelectSystem from '../components/Select/SelectSystem';
import MatchLink from '../components/Match/MatchLink';
import LinearProgress from "@mui/material/LinearProgress";

import { api } from '../components/Functions/API';
import Navbar from './Navbar';
import Modal from '../components/Shared/Modal';
import SystemInfo from '../components/System/SystemInfo';

const Domains = () => {
    const domains = ["attendance", "assessments", "behavior", "demographics", "enrollment", "grades"]
    return (
        <Grid container>
            {domains.map((domain, key) => (
                <Grid key={key} container className="Container">{domain}</Grid>
            ))}
        </Grid>
    )
}

const SystemList = props => {
    const options = props.sysconfig.data ? [
        ...props.sysconfig.data.map(x => ({
            value: x.SystemKey,
            system: x.SystemKey,
            label: x.Name
        })),
        { value: "manual", label: "Manual" },
        { value: "none", label: "None" }
    ] : []
    return (
        <FormSelect
            name={props.name}
            label={props.label}
            options={options}
            value={props.value || ''}
            // handler={e => handleChange(e)}
            handler={props.handler}
        />
    )
}

const System = () => {
    const dispatch = useDispatch();
    const user = useUser();
    const params = useParams();
    const createNew = (window.location.pathname.split('/').pop() === "new")

    const sysconfig = useSelector(state => state.sysconfig);
    const systems = useSelector(state => state.systems);
    const jobs = useSelector(state => state.jobs);
    const [data, setData] = useState();
    const [snackbar, setSnackbar] = useState(null);
    const [modal, setModal] = useState();
    const [selected, setSelected] = useState();

    useEffect(() => {
        user.org && getData()
    }, [user])

    const getData = async () => {
        if (createNew) { setData({}); return }
        const response = await api(user, 'GET', 'systems', params.id, null, [
            x => setData(x),
            x => setSelected(x.SystemKey)
        ])
        response && setSnackbar(response)
    }
    const saveData = async () => {
        setSnackbar({ severity: 'info', message: "Saving Changes" })
        const { PK, SK, LastModified, ...body } = { ...data, ...{ Type: "system" } }
        console.log(body)
        // return 
        const response = await api(user, 'PUT', 'systems', params.id, body, [
            x => setData(x),
            x => updateCache(dispatch, setSystems, systems, x)
        ])
        response && setSnackbar(response)
    }
    const deleteData = async () => {
        setSnackbar({ severity: 'info', message: `Deleting ${data.Name}` })
        const response = await api(user, 'DELETE', 'systems', params.id, null, [
            x => updateCache(dispatch, setSystems, systems, x, params.id)
        ])
        response && setSnackbar(response)
    }

    const handleChange0 = (e, i) => {
        // let newData = { ...data }
        // let newFields = [...fields]

        // // Field change
        // if (i !== undefined) {
        //     if (e.target.name === "column_order") {
        //         newFields.splice(i + e.target.value, 0, newFields.splice(i, 1)[0])
        //         newFields = newFields.map((x, i) => ({ ...x, ...{ column_order: i + 1 } }))
        //     } else if (e.target.name === "delete") {
        //         newFields.splice(i, 1)
        //     } else if (i === -1) {
        //         newFields = [...newFields, ...[{ name: '', type: '', column_order: newFields.length + 1 }]]
        //     } else {
        //         newFields[i] = { ...newFields[i], ...{ [e.target.name]: e.target.value } }
        //     }
        //     // Name, SystemKey, SchemaType change
        // } else {
        //     newData = { ...newData, ...{ [e.target.name]: e.target.value } }
        // }

        // newData = {
        //     ...newData,
        //     ...{
        //         Fields: arrayToFields(newFields),
        //         GSI1PK: `sys#${newData.SystemKey}`,
        //         GSI1SK: `sch#${newData.Name}`
        //     }
        // }
        // setData(newData)
        // setFields(newFields)
    }



    const handleChange = e => {
        console.log(e.target.name, e.target.value)
        e.target.name === "SystemKey" && setSelected(e.target.value)
        console.log({ ...data, ...{ [e.target.name]: e.target.value } })
        setData({ ...data, ...{ [e.target.name]: e.target.value } })
    }

    return (
        <Navbar snackbar={snackbar}>
            <Modal data={modal} close={() => setModal()} delete={deleteData} />
            {data ?
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container>
                            <Title
                                page="Systems"
                                value={data.Name || ''}
                                avatars={[{ system: { icon: sysconfig.data && sysconfig.data.find(x => x.SystemKey === selected)?.Logo } }]}
                                handler={handleChange}
                            />
                            <ActionButtons
                                createNew={createNew}
                                delete={() => setModal(`Are you sure you want to delete ${data.Name}?`)}
                                save={saveData}
                            />

                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className="Container">
                            <Subtitle
                                type="System"
                            />
                            <FormSelectSystems
                                value={data.SystemKey}
                                filter={x => x?.Actions}
                                handler={e => handleChange(e)}
                            />
                        </Grid>
                    </Grid>

                    {/* Records */}
                    <Grid container >
                        <Grid item xs={6} sx={{ pr: 0.5 }}>
                            <Grid container className="Container">
                                <Subtitle type="Roster" />
                                Staff
                                <SystemList
                                    name="RosterStaff"
                                    value={data.RosterStaff}
                                    sysconfig={sysconfig}
                                    handler={e => handleChange(e)}
                                />
                                Students
                                <SystemList
                                    name="RosterStudents"
                                    value={data.RosterStudents}
                                    sysconfig={sysconfig}
                                    handler={e => handleChange(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sx={{ pl: 0.5 }}>
                            <Grid container className="Container">
                                <Subtitle type="Auth" />
                                Staff
                                <SystemList
                                    name="AuthStaff"
                                    value={data.AuthStaff}
                                    sysconfig={sysconfig}
                                    handler={e => handleChange(e)}
                                />
                                Students
                                <SystemList
                                    name="AuthStudents"
                                    value={data.AuthStudents}
                                    sysconfig={sysconfig}
                                    handler={e => handleChange(e)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* 
                    <Grid container className="Container">
                        <Grid item xs={6} sx={{ pr: 0.5 }}>
                            <Subtitle type="Source" />
                            {selected && jobs.data && jobs.data.filter(x => x.StateMachine.split('-')[0] === selected).map((x, key) => {
                                return (
                                    <Grid key={key}>
                                        <MatchLink type="jobs" item={x} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <Grid item xs={6} sx={{ pl: 0.5 }}>
                            <Subtitle type="Target" />
                            {selected && jobs.data && jobs.data.filter(x => x.StateMachine.split('-')[1] === selected).map((x, key) => {
                                return (
                                    <Grid key={key}>
                                        <MatchLink type="jobs" item={x} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                     */}

                    <Grid item xs={12}>
                        {sysconfig.data &&
                            <Grid container className="Container">
                                <FormSelectSystems
                                    value=""
                                />
                                {sysconfig.data.map((x, key) => {
                                    return (
                                        <Pod
                                            key={key}
                                            system={{ icon: x?.Logo }}
                                        >
                                            {x?.Name}
                                        </Pod>
                                    )
                                })}
                            </Grid>
                        }
                    </Grid>
                </Grid>
                :
                <Grid item xs={12}><LinearProgress /></Grid>
            }
        </Navbar>
    )
}

export default System;