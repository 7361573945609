import { useState, useEffect } from "react";
import { useUser } from '../components/Auth/AuthContext';
import { useSelector, useDispatch } from 'react-redux';
import { setJobs, setExecutions } from '../components/Cache/slices';
import API, { api } from '../components/Functions/API';

import Grid from '@mui/material/Grid';

import BoltIcon from "@mui/icons-material/Bolt";
import StorageIcon from "@mui/icons-material/Storage";

import Navbar from "./Navbar";
import Loading from '../components/Shared/Loading';
import ControlPanel from '../components/Shared/ControlPanel';
import Header from '../components/Shared/Header';
import RowResults from "../components/Rows/RowResults";

import actions from '../components/Config/actions';

const jobExecutions = executions => {
    let data = {}
    executions.data && executions.data.forEach(x => {
        const jobId = (x.GSI1PK || '').substring(4)
        data[jobId] = [...(data?.[jobId] || []), x]
    })
    return data
}

const jobSchemas = (jobs, schemas) => {
    let data = {}
    jobs.data && schemas.data && jobs.data.forEach(x => {
        const system = x.StateMachine.split('-').find(y => y != 'sqlserver')
        x.Input.records.forEach(y => {
            data[x.Id] = [
                ...(data?.[x.Id] || []),
                ...schemas.data.filter(z =>
                    z.SystemKey === system && z.Name == y.object
                )
            ]
        }
        )
    })
    return data
}

const Jobs = () => {
    const dispatch = useDispatch()
    const user = useUser();

    const executions = useSelector(state => state.executions);
    const jobs = useSelector(state => state.jobs);
    const schemas = useSelector(state => state.schemas);

    const [data, setData] = useState(jobs.data || []);
    const [flat, setFlat] = useState({});
    const [snackbar, setSnackbar] = useState();

    const options = {
        action: Object.entries(actions).map(([name, item]) => ({
            label: item.label,
            value: name,
            icon: item.icon
        }))
    }

    useEffect(() => {
        setData(jobs.data || [])
        executions && jobs && schemas && setFlat({
            executions: jobExecutions(executions),
            schemas: jobSchemas(jobs, schemas)
        })
    }, [jobs, executions, schemas])

    const updateJobs = job => {
        let newJobs = [...jobs]
        newJobs[newJobs.findIndex(x => x.Id === job.Id)] = job;
        dispatch(setJobs(newJobs))
        setData(newJobs)
    }

    // handlers
    const handleJobEnable = async (e, jobId) => {
        setSnackbar({ severity: 'info', message: "Saving changes" })
        const response = await api(user, 'PUT', 'jobs', jobId, { "Status": e.target.checked ? "ENABLED" : "DISABLED" }, [
            x => {
                let newJobs = [...jobs.data]
                newJobs[newJobs.findIndex(y => y.Id === x.Id)] = x;
                dispatch(setJobs(newJobs))
                setData(newJobs)
            }
        ])
        response && setSnackbar(response)
    }

    const getExecutions = async jobIds => {
        const maxExecutions = 10

        // clear jobId executions from cached executions
        const otherExecutions = [...executions.data].filter(x => !jobIds.includes((x.GSI1PK || '').substring(4)))
        dispatch(setExecutions(otherExecutions))

        for (const jobId of jobIds) {
            const response = await api(user, 'GET', 'jobs', `${jobId}/executions`, null, [
                x => {
                    dispatch(setExecutions([
                        ...otherExecutions,
                        ...x.splice(0, maxExecutions)
                    ].sort((a, b) => { return b.SK - a.SK })))
                }])
            response && setSnackbar(response)
        }
    }

    const runExecution = async item => {
        setSnackbar({ severity: 'info', message: `Starting ${item.Name}` })
        const response = await api(user, "POST", "executions", item.Id, {
            StateMachine: item.StateMachine,
            Input: { ...item.Input, jobId: item.Id }
        }, [])
        response && setSnackbar(response)
    }

    const handleFilter = filter => {
        if (jobs.data) {
            let newData = jobs.data.filter(x => (
                ((filter.search || '').length > 0 ? (x.Name || '').toLowerCase().includes(filter.search.toLowerCase()) : true) &&
                ((filter.Status || '').length > 0 ? x.Status === filter.Status : true) &&
                ((filter.SQLServer || '').length > 0 ? x.StateMachine && x.StateMachine.split('-')[filter.SQLServer === 'source' ? 0 : 1] === "sqlserver" : true) &&
                ((filter.action || '').length > 0 ? [x.Input.source.action, x.Input.target.action].includes(filter.action) : true) &&
                // ((filter.table || '').length > 0 ? x.Config.some(x => x.tables.includes(filter.table)) : true) &&
                // ((filter.table || '').length > 0 ? x.Input.records.map(x=>queries.filter(x=>x.tables.includes(filter.table)).map(x=>x.id).includes(x.queryId)) : true) &&
                ((filter.system || []).length > 0 ? filter.system.some(system => x.StateMachine.split('-').indexOf(system) >= 0) : true)
            ))
            setData(newData)
        }
    }
    return (
        <Navbar snackbar={snackbar}>
            <Grid container>
                <Header
                    type="Job"
                    setData={setJobs}
                    data={data}
                />
                <ControlPanel
                    page="Jobs"
                    handler={handleFilter}
                // selects={[
                //     { name: 'action', label: 'Action', icon: <BoltIcon />, options: options.action },
                //     { name: 'table', label: 'Table', icon: <StorageIcon />, options: [...new Set(queries.map(x => x.tables).flat())].map(x => ({ label: x, value: x })) }
                // ]}
                />

                {/* Results */}
                <RowResults
                    type="jobs"
                    loading={!jobs.data}
                    data={data}
                    handlers={{
                        getExecutions: getExecutions,
                        handleJobEnable: handleJobEnable,
                        jobRun: runExecution
                    }}
                    matches={{
                        executions: x => flat?.executions?.[x] || [],
                        schemas: x => flat?.schemas?.[x] || []
                    }}
                />

            </Grid>
        </Navbar>
    );
};

export default Jobs;
