import { useState, useEffect } from 'react';
import { useUser } from '../Auth/AuthContext';
import {
    Typography, Box, Grid,
    Select, MenuItem, FormControl, InputLabel, LinearProgress
} from '@mui/material';
import AvatarSystem from '../Icons/AvatarSystem'
import { SystemConfig } from '../Config/SystemConfig';
import Filters from '../Shared/Filters';
import Pod from '../Shared/Pod';

const FormOption = props => {
    const option = props.option || {}
    return (
        <Box
            sx={{
                display: 'flex',
                p: props.selected ? 0 : 'auto',
                '& .MuiTypography-root': { ml: 1 }
            }}
        >
            {option.system && <AvatarSystem system={SystemConfig?.[option.system]} />}
            {option.icon}
            <Typography>{option.label}</Typography>
            {option.type && Filters[option?.link].type.find(x=>x.value === option.type)?.icon}
        </Box>
    )
}

const FormSelect = props => {
    const user = useUser();
    const [options, setOptions] = useState(props.options)
    useEffect(() => {
        setOptions(props.options)
    }, [props]);

    return (
        <FormControl variant='standard' fullWidth
        >
            {props.label && <InputLabel shrink={props.header || props.value !== undefined || false}>{props.label}</InputLabel>}

            {options ?
                <Select
                    name={props.name}
                    value={props.value}
                    onChange={props.handler}
                    disabled={user?.role != "Admin" || props.disabled}
                    disableUnderline={props.disableUnderline}
                    IconComponent={props.hideArrow && (() => { })}
                    renderValue={() => <FormOption option={props.options.find(x => x.value === props.value)} selected />}
                >
                    <MenuItem key={-1} value=''>(none)</MenuItem>
                    {props.options && props.options.map((option, key) => {
                        return (
                            <MenuItem
                                key={key}
                                value={option.value}
                            >
                                <FormOption option={option} />
                            </MenuItem>
                        )
                    })}
                </Select>
                :
                <Grid item xs={12} sx={{ '& .MuiLinearProgress-root': { top: '1rem' } }}><LinearProgress /></Grid>
            }
        </FormControl>
    )
}
export default FormSelect;