import { useState, useEffect } from 'react';
import { Snackbar, Alert, Grid, Box } from '@mui/material';
import logo from '../../images/logo-min-white.svg';

const SnackbarSolo = props => {
    const [data, setData] = useState(null);
    const width = (data || {}).severity === "error" ? "800px" : "300px"
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        props?.data ? setData(props?.data) : setData(null)
    }, [props.data])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setData(null)
    }

    const Logo = () => (
        <Box sx={{
            display: 'inline-block',
            '& #img-logo': {
                height: 16,
                width: 16
            }
        }}><img id="img-logo" src={logo} /></Box>
    )

    // add seconds to snackbar if timer = true
    useEffect(() => {
        let secs = 0
        if (data?.timer) {
            const interval = setInterval(() => {
                secs += 1
                setSeconds(secs)
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setSeconds(0)
        }
    }, [data])

    return (
        <Snackbar
            open={data !== null}
            autoHideDuration={data ? data.duration || 3000 : 3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleClose}
            sx={{
                width: width,
                minWidth: 400,
                '& .MuiAlert-filledSuccess': { color: 'rgba(255, 255, 255, 1)' } // makes success text white on green
            }}
        >
            <Alert
                variant='filled'
                onClose={handleClose}
                severity={data ? data.severity : 'info'}
                sx={{ width: '100%', overflow: 'auto' }}
            >
                <Grid container>
                    <Grid item>
                        {data && ((typeof data.message === 'object') ? JSON.stringify(data.message) : data.message || "")}
                        {data && data.timer && ` ${seconds}s`}
                        {data && data.timer && [...Array(seconds).keys()].map(() => (".")).join("")}
                        {data && data.timer && <Logo />}
                        
                    </Grid>
                </Grid>
            </Alert>
        </Snackbar>
    )

}
export default SnackbarSolo;