import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setUsers } from '../components/Cache/slices';

import Grid from '@mui/material/Grid';
import Loading from '../components/Shared/Loading';
import Header from '../components/Shared/Header';

import RowUser from '../components/Rows/RowUser';
import ControlPanel from '../components/Shared/ControlPanel';
import Navbar from './Navbar';

const Users = () => {
    const users = useSelector(state => state.users);
    const [data, setData] = useState(users.data || []);
    const [snackbar, setSnackbar] = useState();

    useEffect(() => {
        setData(users.data || [])
    }, [users])

    // handlers
    const handleFilter = filter => {
        if (users.data) {
            let newData = (users.data || []).filter(x => (
                ((filter.search || '').length > 0 ? (x.Name || '').toLowerCase().includes(filter.search.toLowerCase()) : true) &&
                ((filter.system || []).length > 0 ? filter.system.some(system => x.SK.split('#')[0] === system) : true)
            ))
            setData(newData)
        }
    }

    return (
        <Navbar snackbar={snackbar}>
            <Grid container>
                <Header
                    type="Users"
                    setData={setUsers}
                    data={data}
                />
                {/* Control Panel */}
                <ControlPanel
                    page="Users"
                    handler={handleFilter}
                />

                {/* Results */}
                {users === null
                    ?
                    <Loading />
                    :
                    <Grid container>
                        {data && data.map((item, key) => {
                            return (
                                <RowUser
                                    key={key}
                                    item={item}
                                />
                            )
                        })
                        }
                    </Grid>
                }
            </Grid>
        </Navbar>
    )
}

export default Users;