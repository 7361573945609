import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from '@mui/icons-material/Save';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RefreshIcon from '@mui/icons-material/Refresh';
import LaunchIcon from "@mui/icons-material/Launch";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import SchemaIcon from '@mui/icons-material/Schema';
import TocIcon from "@mui/icons-material/Toc";
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ButtonsConfig = {
    save: {
        startIcon: <SaveIcon />,
        label: "Save",
        color: "success",
        disabled: true
    },
    delete: {
        startIcon: <DeleteIcon />,
        label: "Delete",
        color: "error",
        disabled: true
    },
    new: {
        startIcon: <AddIcon />,
        label: "New",
        color: "primary",
        disabled: true
    },
    run: {
        startIcon: <PlayArrowIcon />,
        label: "Run",
        color: "primary",
        disabled: true
    },
    query: {
        startIcon: <PlayArrowIcon />,
        label: "Run Query",
        color: "primary",
        disabled: true
    },
    refresh: {
        startIcon: <RefreshIcon />,
        label: "Refresh",
        color: "primary",
        disabled: false
    },
    launch: {
        startIcon: <LaunchIcon />,
        label: "Launch",
        color: "primary",
        disabled: false
    },
    jobs: {
        startIcon: <WorkOutlineIcon />,
        label: null,
        color: "primary",
        disabled: false
    },
    schemas: {
        startIcon: <TocIcon />,
        label: null,
        color: "primary",
        disabled: false
    },
    executions: {
        startIcon: <ViewTimelineOutlinedIcon />,
        label: null,
        color: "primary",
        disabled: false
    },
    records: {
        startIcon: <TableRowsIcon />,
        label: null,
        color: "primary",
        disabled: false
    },
    arrowUp: {
        startIcon: <ArrowDropUpIcon />,
        label: null,
        color: "primary",
        disabled: false
    },
    arrowDown: {
        startIcon: <ArrowDropDownIcon />,
        label: null,
        color: "primary",
        disabled: false
    },
    enable: {
        labelOn: "Enabled",
        labelOff: "Disabled",
        color: "success",
        disabled: true
    }

}
export default ButtonsConfig