import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useUser } from "../Auth/AuthContext";
import {
    FormLabel, Grid, InputAdornment, TextField, Chip
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import SystemIcons from '../Shared/SystemIcons';
import ControlPanelFilters from './ControlPanelFilters';
import { SystemConfig } from "../Config/SystemConfig";
import Pod from "./Pod";
import AvatarSystem from "../Icons/AvatarSystem";
import Subtitle from '../Shared/Subtitle';
const cssControlPanel = { border: '1px solid #ccc', borderRadius: 1, p: 1, mb: 1 }

const ControlPanel = props => {
    const user = useUser();
    const [filter, setFilter] = useState({})

    const handleFilter = e => {
        const newFilter = { ...filter, [e.target.name]: e.target.value }
        setFilter(newFilter)
        if (e.target.name !== 'search') {
            props.handler(newFilter)
        }
    }

    const data = useSelector(state => (state[props.page.toLowerCase()] || {}).value)

    useEffect(() => {
        setFilter(data === null ? {} : filter)
    }, [data])

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            props.handler({ ...filter, search: filter.search })
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [filter.search]);

    return <Grid item xs={12}>
        <Grid container sx={cssControlPanel}>
            <Grid item xs={props.hideSystems ? 12 : 6}>
                <Grid container >

                    {/* Search */}
                    <FormLabel>Search</FormLabel>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="standard"
                            type="search"
                            name="search"
                            value={filter.search || ''}
                            onChange={handleFilter}
                            autoComplete='off'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    {/* Filters */}
                    <ControlPanelFilters
                        page={props.page}
                        filter={filter}
                        handler={handleFilter}
                        selects={props.selects}
                        reset={data === null}
                    />
                </Grid>
            </Grid>
            {!props.hideSystems &&
                <Grid item xs={6}>
                    {/* <FormLabel>Systems</FormLabel> */}
                    <Subtitle type="System" />
                    {/* {Object.entries(SystemConfig).filter(x => x[0] !== '').map(([k, v], key) => {
                        console.log(filter.system)
                        return (
                            <Pod key={key} handler={() => handleFilter({target: {name: 'system', value:[...(filter.system || []), k]}})}>
                                <Grid container><AvatarSystem system={v} />{v.label}</Grid>
                            </Pod>
                    )
                    })} */}
                    {user.org &&
                        <SystemIcons
                            changed={handleFilter}
                            exclusive={props.exclusive || false}
                            filter={filter}
                        />
                    }
                </Grid>
            }
        </Grid>
    </Grid>
}

export default ControlPanel;