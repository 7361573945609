import { useEffect, useState } from 'react';
import { useUser } from '../components/Auth/AuthContext';
import { useSelector, useDispatch } from 'react-redux';
import { setExecutions, setSchemas } from '../components/Cache/slices';
import { api } from '../components/Functions/API';

import Grid from '@mui/material/Grid';

import Navbar from './Navbar';
import ControlPanel from '../components/Shared/ControlPanel';
import Header from '../components/Shared/Header';
import Filters from '../components/Shared/Filters';
import RowResults from '../components/Rows/RowResults';

const schemaExecutions = (executions, matchedJobs) => {
    let schemaExecutions = {}
    Object.keys(matchedJobs).forEach(x =>
        schemaExecutions[x] = matchedJobs[x].map(y =>
            executions.data.filter(z => (z.GSI1PK || '').substring(4) === y.Id)
        ).flat()
    )
    return schemaExecutions
}

const schemaJobs = (jobs, schemas) => {
    let schemaJobs = {}

    schemas.data.forEach(x =>
        schemaJobs[x.Id] = jobs.data.filter(y =>
            y.Input.records.find(z =>
                x.SystemKey === y.Input.source.system && x.Name === z.object
            )
        )
    )
    return schemaJobs
}

const Schemas = () => {
    const dispatch = useDispatch()
    const user = useUser();

    const executions = useSelector(state => state.executions);
    const jobs = useSelector(state => state.jobs);
    const schemas = useSelector(state => state.schemas);

    const [data, setData] = useState(schemas.data || []);
    const [snackbar, setSnackbar] = useState();
    const [flat, setFlat] = useState({});

    useEffect(() => {
        setData(schemas.data || [])
        executions.data && jobs.data && schemas.data && handleFlat()
    }, [jobs, executions, schemas])

    const handleFlat = () => {
        const matchedJobs = schemaJobs(jobs, schemas)
        setFlat({
            executions: schemaExecutions(executions, matchedJobs),
            jobs: matchedJobs
        })
    }

    const getExecutions = async jobIds => {
        const maxExecutions = 10

        // clear jobId executions from cached executions
        const otherExecutions = [...executions.data].filter(x => !jobIds.includes((x.GSI1PK || '').substring(4)))
        dispatch(setExecutions(otherExecutions))

        for (const jobId of jobIds) {
            const response = await api(user, 'GET', 'jobs', `${jobId}/executions`, null, [
                x => {
                    dispatch(setExecutions([
                        ...otherExecutions,
                        ...x.splice(0, maxExecutions)
                    ].sort((a, b) => { return b.SK - a.SK })))
                }])
            response && setSnackbar(response)
        }
    }

    // handlers
    const handleFilter = filter => {
        if (schemas.data) {
            let newData = schemas.data.filter(x => (
                (filter.search && filter.search.length > 0 ? (x.Name || '').toLowerCase().includes(filter.search.toLowerCase()) : true) &&
                (filter.type !== undefined ? x.SchemaType === filter.type : true) &&
                (filter.system && filter.system.length > 0 ? filter.system.some(system => x.SystemKey === system) : true)
            ))
            setData(newData)
        }
    }

    return (
        <Navbar snackbar={snackbar}>
            <Grid container>
                <Header
                    type="Schema"
                    setData={setSchemas}
                    data={data}
                />
                <ControlPanel
                    page={"Schemas"}
                    filterItems={Filters.Schemas.type}
                    handler={handleFilter}
                />

                {/* Results */}
                <RowResults
                    type="schemas"
                    loading={!schemas.data}
                    data={data}
                    handlers={{
                        getExecutions: getExecutions
                    }}
                    matches={{
                        executions: x => flat?.executions?.[x] || [],
                        jobs: x => flat?.jobs?.[x] || []
                    }}
                />
            </Grid>
        </Navbar>
    )
}

export default Schemas;