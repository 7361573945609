import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUser } from '../components/Auth/AuthContext';
import { api } from '../components/Functions/API';
import _ from 'lodash';

import { jobIcons, executionStepPayload } from '../components/Shared/Functions';
import ExecutionDetails from '../components/Execution/ExecutionDetails';
import Title from '../components/Shared/Title';
import Grid from '@mui/material/Grid';
import Loading from '../components/Shared/Loading';
import Button from '../components/Buttons/Button';
import Navbar from './Navbar';
import { SystemConfig } from '../components/Config/SystemConfig';

const Execution = () => {
    const createNew = (window.location.pathname.split('/').pop() === "new")
    const user = useUser();
    const params = useParams();
    const schemas = useSelector(state => state.schemas);

    const [data, setData] = useState();
    const [snackbar, setSnackbar] = useState();

    // Schema-specific
    const first = data && data.length > 0 && data[0]
    const last = data && data.length > 0 && data.slice(-1)[0]
    const input = data && data.length > 0 && executionStepPayload(first)
    const output = data && data.length > 0 && executionStepPayload(last)
    const systems = data && data.length > 0 && jobIcons(input.payload)
    const href = `https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/executions/details/arn:aws:states:us-east-1:690904019624:execution:${params.id}`

    useEffect(() => {
        user.org && getData()
    }, [user])

    const getData = async () => {
        if (createNew) { setData({}); return }
        const id = `arn:aws:states:us-east-1:690904019624:execution:${params.id}`
        const response = await api(user, 'GET', 'executions', id, null, [
            x => setData(x)
        ])
        response && setSnackbar(response)
    }

    // Schema-specific 

    return (
        <Navbar snackbar={snackbar}>
            {data ?
                <Grid container>
                    <Grid item xs={12} sx={{ mb: 3 }}>
                        <Grid container>
                            <Title
                                page="Connections"
                                value={input && input.payload.jobName || ''}
                                systems={systems}
                                avatars={[{
                                    system: input && SystemConfig?.[input.payload.source.system],
                                },
                                {
                                    system: input && SystemConfig?.[input.payload.target.system],
                                }]}
                                href={`/jobs/${input && input.payload.jobId}`}
                                disabled
                            />
                            <Button type="launch" label="AWS" handler={() => window.open(href, '_blank', 'noopener,noreferrer')} />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        {data.length > 0 &&
                            <ExecutionDetails
                                data={data}
                                output={output}
                                schemas={schemas.data}
                            />
                        }
                    </Grid>

                </Grid >

                :
                <Loading />
            }
        </Navbar>
    )
}
export default Execution;