import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import MatchLabel from "./MatchLabel";
import MatchLink from "./MatchLink";
import ExecutionBlock from "../Execution/ExecutionBlock";

const Match = props => {
    const [showMatches, setShowMatches] = useState(false);
    return (
        <Grid container className="Match">
            <Grid item className="MatchButton" >
                <MatchLabel
                    type={props.type}
                    matches={props.matches}
                    showMatches={showMatches}
                    toggle={() => setShowMatches(!showMatches)}
                    handler={props.handler}
                />
            </Grid>
            <Grid item xs={12} className="Matches">
                <Grid container>
                    {showMatches && props.type === "executions" && props.matches.map((item, key) => {
                        const maxWidth = props.matches.reduce((prev, current) => (prev && prev.Duration > current.Duration) ? prev : current).Duration
                        return (
                            <ExecutionBlock
                                key={key}
                                item={item}
                                maxWidth={maxWidth}
                                href
                            />
                        )
                    })}
                    {showMatches && props.type !== "executions" && props.matches.map((item, key) => {
                        return <MatchLink key={key} type={props.type} item={item} />
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}
export default Match;