import { useUser } from '../Auth/AuthContext';
import { useTheme } from '@mui/material/styles';
import MuiButton from "@mui/material/Button";
import MuiIconButton from "@mui/material/IconButton";

import AddIcon from "@mui/icons-material/Add";
import ButtonsConfig from './ButtonConfig';

const Button = props => {
    const user = useUser();
    const theme = useTheme();
    const btn = ButtonsConfig[props.type]

    return props.small ?
        <MuiIconButton
            onClick={props.handler}
            className="ButtonSmall"
            disabled={props.disabled || (user?.role === "Admin" ? false : btn.disabled)}
            sx={{ 
                color: theme.palette.neutral.main,
                ':hover': { color: theme.palette[btn.color || 'neutral'].main } 
            }}
        >
            {btn.startIcon}
        </MuiIconButton>
        :
        <MuiButton
            variant={props.variant || "text"}
            color={props.color || btn.color}
            startIcon={props.createNew ? <AddIcon /> : btn.startIcon}
            onClick={props.handler}
            disabled={props.disabled || (user?.role === "Admin" ? false : btn.disabled)}
            disableElevation
            sx={{ minWidth: '2rem' }}
        >
            {props.createNew ? 'Create' : props.label || btn.label}
        </MuiButton>
}
export default Button