import { useState, useEffect } from "react";
import { Modal, Grid } from '@mui/material';
import Button from '../Buttons/Button';

const DashModal = props => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props?.data !== undefined)
    }, [props.data])

    return (
        <Modal
            open={open || false}
            onClose={props.close}
        >
            <Grid item className="Modal">
                <Grid container>
                    <Grid item xs={12}>
                        {props?.data}
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: 'right'}}>
                        {props.delete && <Button type="delete" handler={props.delete} />}
                    </Grid>
                </Grid>

            </Grid>
        </Modal>
    )
}
export default DashModal;