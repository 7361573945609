import { SSMClient, GetParameterCommand, PutParameterCommand, DeleteParameterCommand } from '@aws-sdk/client-ssm';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import cognitoAuthConfig from '../../config';

const ssm = (user) => {
    return new SSMClient({
        region: cognitoAuthConfig.region,
        credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: cognitoAuthConfig.region }),
            identityPoolId: cognitoAuthConfig.identityPoolId,
            logins: {
                [`cognito-idp.${cognitoAuthConfig.region}.amazonaws.com/${cognitoAuthConfig.userPoolId}`]: user.id_token
            },
        })
    })
};

const GetSecureValue = async (user, value) => {
    let command = new GetParameterCommand({
        Name: value,
        WithDecryption: true
    });
    let result = await ssm(user).send(command).then(
        (data) => {
            return data.Parameter.Value
        },
        (error) => {
            console.log(value, 'error', error)
            return ""
        }
    )
    return result
}
const PutSecureValue = async (user, key, value) => {
    let command = new PutParameterCommand({
        Name: key,
        Value: value,
        Type: 'SecureString',
        Overwrite: true
    });
    let result = await ssm(user).send(command).then(
        (data) => {
            console.log('success', data)
            return
        },
        (error) => {
            console.log('error', error)
        }
    )
    return result
}

const DeleteSecureValue = async (user, key) => {
    let command = new DeleteParameterCommand({
        Name: key,
    });
    let result = await ssm(user).send(command).then(
        (data) => {
            return data
        },
        (error) => {
            console.log(error)
        }
    )
    return result
}

export { GetSecureValue, PutSecureValue, DeleteSecureValue }