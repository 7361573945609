import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const Option = props => (
    <Grid container>
        {props.Logo && <Avatar src={props.Logo} />}
        {props.Name}
    </Grid>
)

const FormSelectSystems = props => {
    const sysconfig = useSelector(state => state.sysconfig)
    const selected = sysconfig.data ? sysconfig.data.find(x => x.SystemKey === props.value) : {}
    const options = sysconfig.data ? sysconfig.data.filter(props?.filter || (()=>true)) : []

    return (
        <Select
            name="SystemKey"
            variant="standard"
            value={props.value || ''}
            onChange={props.handler}
            renderValue={() => <Option {...selected} />}
            className="SelectSystems"
        >
            {options.length > 0 && options.map((x, key) => {
                return (
                    <MenuItem
                        key={key}
                        value={x.SystemKey}
                        className="Option"
                    >
                        <Option {...x} />
                    </MenuItem>
                )
            })}
        </Select>
    )
}

export default FormSelectSystems;