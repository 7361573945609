import Grid from '@mui/material/Grid';
import ExecutionDetailRow from './ExecutionDetailRow';
import Subtitle from '../Shared/Subtitle';
import emap from './ExecutionDetailMap';

const ExecutionDetails = props => {
    const data = props.data
    const maproot = x => (emap[x.type] || {})

    const makeBlockItems = first => {
        var steps = [first]
        var i = 1
        while (true) {
            const step = data.filter(x => x.previousEventId === steps[i - 1].id)
            if (step.length > 0) {
                i += 1
                steps = [...steps, ...step]
            } else {
                i = 1
                break
            }
        }
        return steps
    }

    const makeSteps = (starts, ends) => {
        let steps = []
        starts.forEach((start, i) => {
            const end = ends[i] || execution[1]
            const input = start[maproot(start).key]
            const output = end[maproot(end).key]
            // console.log(JSON.parse(output?.output || "{}"))
            const item = {
                Input: JSON.parse(input?.input || "{}"),
                Output: JSON.parse(output?.output || "{}"),
                StartTime: start.timestamp,
                Duration: (new Date(end.timestamp) - new Date(start.timestamp)) / 1000,
                Status: maproot(end).Status,
                Buffer: (new Date(start.timestamp) - new Date(starts[0].timestamp)) / (maxWidth * 1000),
                status: maproot(end).status,
                error: output?.error && JSON.parse(output?.cause || "{}").errorMessage
            }
            steps[i] = item
        })
        return steps
    }

    const iterations = data.filter(x => x.type === "MapIterationStarted").map(x => makeBlockItems(x))
    const maxWidth = (new Date(data.slice(-1)[0].timestamp) - new Date(data[0].timestamp)) / 1000

    const execution = [data[0], data.slice(-1)[0]]
    const firstStep = makeSteps([data[0]], [data.slice(-1)[0]])[0]
    // console.log(firstStep.Output.records.reduce((n, { row_count }) => n + row_count, 0))
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container className="Container">
                    <Subtitle type="Execution" />
                    <ExecutionDetailRow
                        step={makeSteps([data[0]], [data.slice(-1)[0]])[0]}
                        node="source"
                        maxWidth={maxWidth}
                        schemas={props.schemas}
                        date
                    />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container className="Container">
                    <Subtitle type="Step" />
                    {iterations.map((iteration, key) => {
                        const tasks = iteration.filter(x => x?.type.substring(0, 4) === 'Task')
                        const steps = makeSteps(
                            tasks.filter(x => maproot(x).type === 'start'),
                            tasks.filter(x => maproot(x).type === 'end')
                        )
                        return (
                            <Grid key={key} item xs={12}>
                                {steps.map((step, key) => {
                                    const node = key === 0 ? 'source' : 'target'
                                    return (
                                        <ExecutionDetailRow
                                            key={key}
                                            step={step}
                                            node={node}
                                            maxWidth={maxWidth}
                                            schemas={props.schemas}
                                        />
                                    )
                                })}

                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}
export default ExecutionDetails;