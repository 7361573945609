import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Loading from '../Shared/Loading';
import Subtitle from '../Shared/Subtitle';
import ExecutionBlock from '../Execution/ExecutionBlock';

const JobExecutions = props => {
    const [data, setData] = useState()

    useEffect(() => {
        // console.log(props, props.data)
        setData(props.data)
    }, [props])

    return (
        <Grid container className="Container">
            <Subtitle
                type="Executions"
                count={props.data?.length}
                refresh
                handler={props.handler}
            />

            <Grid item xs={12} sx={{
                maxHeight: 160,
                overflowX: 'hidden',
                overflowY: 'auto',
            }}>
                {data ? data.map((item, key) => {
                    return (
                        <Grid container key={key} sx={{ borderTop: '1px dotted #ccc', py: 0.5 }}>
                            <ExecutionBlock
                                item={item}
                                maxWidth={Math.max(...data.map(x => x.Duration))}
                                date
                                href
                            />
                        </Grid>
                    )
                })
                    :
                    <Loading />
                }
            </Grid>
        </Grid>
    )
}
export default JobExecutions;