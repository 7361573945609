import { useState, useEffect } from "react";
import { Avatar, Box, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SystemConfig } from '../Config/SystemConfig';
import { useTheme } from '@mui/material/styles';

const SystemIcons = (props) => {
    const theme = useTheme();
    const systems = Object.keys(SystemConfig).filter(x => x !== '')

    const handleSystemChange = (e, value) => {
        props.changed({ target: { name: 'system', value: value } })
    };
    return (
        <ToggleButtonGroup
            onChange={handleSystemChange}
            value={props.filter.system}
            exclusive={props.exclusive}
            className="SystemIcons"
            sx={{
                flexWrap: 'wrap',
                border: 'none',
                '.MuiButtonBase-root': { border: 'none' },
                '.MuiDisabled .MuiAvatar-root .MuiAvatar-img': { opacity: 0.5 },
                '& .Mui-selected': {
                    outline: `1px solid ${theme.palette.primary.main}`,
                    background: theme.palette.offwhite.main//'white'
                }
            }}
        >
            {systems.map((item, index) => {
                return (
                    <ToggleButton
                        key={index}
                        value={item}
                        color='primary'
                        // disabled
                        // sx={{
                        //     flexGrow: 0,
                        //     p: 1,
                        //     border: 'none',
                        // }}
                    >
                        <Avatar
                            src={SystemConfig[item].icon}
                            sx={{ width: 32, height: 32 }}
                        />
                    </ToggleButton>
                )
            })}
        </ToggleButtonGroup>
    )
}

export default SystemIcons;