import { useUser } from '../components/Auth/AuthContext';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Execution from './Execution';
import Executions from './Executions';
import Connections from './Connections';
import Connection from './Connection';
import Jobs from './Jobs';
import Job from './Job';
import Queries from './Queries';
import Query from './Query';
import Schemas from './Schemas';
import Schema from './Schema';
import Systems from './Systems';
import System from './System';
import User from './User';
import Users from './Users';
import Error404 from './Error404';
import Login from './Login';

import { useAuth } from "react-oidc-context";

export default function Router() {
    const auth = useAuth();
    const loggedIn = auth.isLoading || auth.isAuthenticated

    return (
        <BrowserRouter>
            <Routes>
                {/* <Route path='/' element={<Executions />} /> */}
                {/* <Route path='/' element={<Home home={<Executions />} />} /> */}
                <Route path='/' element={<Login />} />
                <Route path='/connections' element={loggedIn ? <Connections /> : <Login />} />
                <Route path='/connections/:id' element={loggedIn ? <Connection /> : <Login />} />
                <Route path='/executions' element={loggedIn ? <Executions /> : <Login />} />
                <Route path='/executions/:id' element={loggedIn ? <Execution /> : <Login />} />
                <Route path='/jobs' element={loggedIn ? <Jobs /> : <Login />} />
                <Route path='/jobs/:id' element={loggedIn ? <Job /> : <Login />} />
                <Route path='/queries' element={loggedIn ? <Queries /> : <Login />} />
                <Route path='/queries/:id' element={loggedIn ? <Query /> : <Login />} />
                <Route path='/schemas/' element={loggedIn ? <Schemas /> : <Login />} />
                <Route path='/schemas/:id' element={loggedIn ? <Schema /> : <Login />} />
                <Route path='/systems/' element={loggedIn ? <Systems /> : <Login />} />
                <Route path='/systems/:id' element={<System />} />
                <Route path='/users/' element={loggedIn ? <Users /> : <Login />} />
                <Route path='/users/:id' element={loggedIn ? <User /> : <Login />} />
                <Route path='*' element={loggedIn ? <Error404 /> : <Login />} />
            </Routes>
        </BrowserRouter>
    )
}