import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ExecutionBlock from './ExecutionBlock';
import Pod from '../Shared/Pod';
import { operOptions } from '../Shared/Functions';
import { SystemConfig } from '../Config/SystemConfig';
import AvatarSystem from '../Icons/AvatarSystem';
import TitleLink from '../Shared/TitleLink';

const ExecutionDetailRow = props => {
    const output = Array.isArray(props.step.Output) ? props.step.Output : [props.step.Output]
    const system = props.step.Input[props.node].system
    const rowcount = (
       props.node === "target" ? output : output.map(x=>x.records || {}).flat()
    ).reduce((n, { row_count }) => n + (row_count || 0), 0)
    // console.log(props.node, output, output ? 1 : 2)
    // console.log(output)
    console.log(rowcount)

    return (
        <Grid container
            sx={{ borderTop: '1px dotted #ccc', py: 1 }}
        >
            <Grid item xs={2}>
                {props.step.Input.records.map((record, key) => {
                    const schema = props.schemas ? props.schemas.find(x => x.SystemKey === system && x.Name === record.object) || {} : {}
                    return (
                        <Grid container key={key} sx={{ py: 0.25 }}>
                            <Pod
                                // icon={<AvatarSystem system={SystemConfig[props.step.Input[props.node].system]} />}
                            >
                                <TitleLink
                                    href={schema?.Id && `/schemas/${schema.Id}`}
                                    avatars={[{system: SystemConfig[system]}]}
                                    label={record.object}
                                />
                                {/* {record.object} */}
                            </Pod>
                            {record.params && record.params.map((param, key) => (
                                <Box
                                    key={key}
                                    sx={{
                                        fontSize: '0.75rem',
                                        color: '#888',
                                        mt: -0.25,
                                        ml: 2
                                    }}
                                >
                                    {param.field} {operOptions.find(o => o.value === param.operator)?.label} {param.value}
                                </Box>
                            ))}
                        </Grid>
                    )
                })}

            </Grid>
            <Grid item xs={9}>
                <ExecutionBlock item={props.step} maxWidth={props.maxWidth} date={props.date} />
            </Grid>
            <Grid item xs={1}>
                {rowcount} rows
            </Grid>
        </Grid>
    )
}
export default ExecutionDetailRow;