import { useEffect, useState } from 'react';
import { useUser } from '../components/Auth/AuthContext';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUsers } from '../components/Cache/slices';

import { api } from '../components/Functions/API';
import _ from 'lodash';

import { updateCache } from '../components/Shared/Functions';

import Grid from '@mui/material/Grid';
import ActionButtons from '../components/Buttons/ActionButtons';
import FormSelect from '../components/Form/FormSelect';
import FormTextField from '../components/Form/FormTextField';
import UserName from '../components/User/UserName';
import Filters from '../components/Shared/Filters';

import Navbar from './Navbar';
import Modal from '../components/Shared/Modal';

const User = () => {
    const dispatch = useDispatch();
    const user = useUser();
    const params = useParams();
    const createNew = (window.location.pathname.split('/').pop() === "new")

    const users = useSelector(state => state.users);
    const [data, setData] = useState(users.find(x => x.Id === params.id) || [])
    const [snackbar, setSnackbar] = useState(null);
    const [modal, setModal] = useState();

    useEffect(() => {
        user.org && getData()
    }, [user])

    const getData = async () => {
        if (createNew) { setData({}); return }
        const response = await api(user, 'GET', 'users', params.id, null, [
            x => setData(x),
            x => updateCache(dispatch, setUsers, users, x)
        ])
        response && setSnackbar(response)
    }

    const handleChange = (e, i) => {
        let newData = [...data]
        if (e.target.name === "column_order") {
            newData.splice(i + e.target.value, 0, newData.splice(i, 1)[0])
            newData = newData.map((x, i) => ({ ...x, ...{ column_order: i + 1 } }))
        } else {
            newData[i] = { ...newData[i], ...{ [e.target.name]: e.target.value } }
        }
        setData(newData)
    }

    return (
        <Navbar snackbar={snackbar}>
            {/* <Modal data={modal} close={() => setModal()} delete={deleteData} /> */}
            <Grid container>
                <Grid item xs sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                    <UserName
                        {...data}
                        handler={handleChange}
                    />
                </Grid>
                {/* Actions */}
                <ActionButtons
                    save={() => { console.log('save') }}
                />
                {/* {["Email", "UserPoolId"].map((item, key) => (
                <Grid item xs={12} key={key}>
                    <FormTextField
                        name={item.replace(' ', '')}
                        label={item}
                        value={data[item.replace(' ', '')]}
                        disabled={federated}
                        handler={null}
                    />
                </Grid>
            ))} */}

                <Grid item xs={12}>
                    <FormTextField
                        name="Email"
                        label="Email"
                        value={data.Email}
                        // disabled={federated}
                        handler={null}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormSelect
                        name="Role"
                        label="Role"
                        value={data.Role}
                        options={Filters.Users.Role}
                        handler={null}
                    />
                </Grid>
                <Grid item xs={12}>
                    Receives:
                    <Grid container className="Container">
                        {['Job Failures', 'Thing 2', 'Thing 3'].map((item, key) => (
                            <Grid item xs={1} key={key}>
                                {item}
                                <FormSelect
                                    name={item.toLowerCase().replace(' ', '')}
                                    // label={item}
                                    value={""}
                                    options={[]}
                                    handler={null}
                                />
                                {/* {item.toLowerCase().replace(' ', '')} */}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                {JSON.stringify(data)}
                {/* {console.log(Auth.user)} */}
            </Grid >
        </Navbar>
    )
}
export default User