import { useDispatch } from 'react-redux';
import {
    Grid, Typography
} from '@mui/material';
import Since from '../Shared/Since';
import Button from '../Buttons/Button';
import pluralize from 'pluralize';

const Header = props => {
    pluralize.addIrregularRule('schema', 'schemas')
    const dispatch = useDispatch()
    const css = {
        display: 'flex',
        textAlign: 'right',
        alignItems: 'center',
        '& .MuiButton-root': { ml: 1 }
    }
    return (
        <Grid container>
            <Grid item xs sx={{ ml: 0.5 }}
            >
                <Typography variant="h6">{props.type} Count ({props.data?.length})</Typography>
                <Since type={pluralize.singular(props.type.toLowerCase())} />
            </Grid>
            <Grid item
                sx={css}
            >
                {!props.hideNew &&
                    <Button
                        type="new"
                        label={`New ${props.type}`}
                        handler={() => window.open(`${pluralize(props.type.toLowerCase())}/new`, '_blank', 'noopener,noreferrer')}
                        variant="outlined"
                    />
                }
                <Button
                    type="refresh"
                    handler={() => dispatch(props.setData(null))}
                    variant="outlined"
                />
            </Grid>
        </Grid>
    )
}
export default Header