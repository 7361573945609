import React, { useState, useEffect } from 'react';
import {
    Grid, Chip, FormLabel, Typography
} from "@mui/material";
import FormSelect from '../Form/FormSelect';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Filters from './Filters';

const ControlPanelFilters = props => {
    const [selected, setSelected] = useState({})
    const handleFilter = (type, value) => {
        let newSelected = { ...selected }
        if (newSelected[type] === value) {
            delete newSelected[type]
        } else {
            newSelected = { ...selected, [type]: value }
        }
        setSelected(newSelected)
        props.handler({ target: { name: type, value: newSelected[type] } })
    }
    return (
        <Grid item xs={12}
            sx={{
                '& .MuiGrid-container': { mt: 0.5 }
            }}
        >
            <FormLabel id="label-filters">Filters</FormLabel>
            {
                Object.entries(Filters[props.page]).map(([type, filters], i) => (
                    <Grid container key={i}>
                        {filters.map((item, j) => (
                            <Chip
                                key={j}
                                variant={props.filter[type] === item.value ? "filled" : "outlined"}
                                onClick={() => handleFilter(type, item.value)}
                                icon={item.icon}
                                label={item.label}
                                color={item.color}
                                sx={{ mr: 0.5 }}
                            />
                        ))}
                    </Grid>
                ))
            }
            {props.selects && props.selects.map((select, key) => {
                return (
                    <Grid container key={key}>
                        <Autocomplete
                            disablePortal
                            fullWidth
                            options={select.options}
                            onChange={(e, v) => handleFilter(select.type, v)}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label={select.label}
                                    variant="standard"
                                />
                            }
                        />
                    </Grid>
                )
            })}
            {/* {props.selects && props.selects.map((select, key) => {
                return (
                    <Grid container
                        key={key}
                        sx={{
                            border: '1px solid #ddd',
                            borderRadius: '2rem',
                            '& .MuiGrid-root, .MuiInputBase-root': { p: 0, m: 0 },
                            '& .MuiGrid-container': { ml: 0.5, mt: 0.5 },
                            '& .MuiTypography-root': { fontSize: '0.8rem', mt: 0.5 },
                            '& .MuiFormControl-root': { pr: 2 }

                        }}
                    >
                        <Grid item xs={2}>
                            <Grid container >
                                <Grid item xs={4}>{select.icon}</Grid>
                                <Grid item xs={8}><Typography>{select.label}</Typography></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={10}>
                            <FormSelect
                                key={key}
                                name={select.name}
                                value={props.filter[select.name] || ''}
                                options={select.options}
                                handler={e => handleFilter(e.target.name, e.target.value)}
                                disableUnderline
                            />
                        </Grid>
                    </Grid>
                )
            })} */}
        </Grid >
    )
}

export default ControlPanelFilters;