import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setExecutions } from '../components/Cache/slices';

import Grid from '@mui/material/Grid';
import ControlPanel from '../components/Shared/ControlPanel';
import Header from '../components/Shared/Header';

import Navbar from './Navbar';
import RowResults from '../components/Rows/RowResults';

const flatten = executions => {
    let jobs = {}
    executions.data.forEach(x => {
        const jobId = (x.GSI1PK || '').substring(4)
        jobs[jobId] = [...(jobs?.[jobId] || []), x]
    })
    return jobs
}

const Executions = () => {
    const executions = useSelector(state => state.executions);

    const [data, setData] = useState(executions ? executions.data : []);
    const [flat, setFlat] = useState({});
    const [maxWidth, setMaxWidth] = useState(0);

    useEffect(() => {
        if (executions.data) {
            setData(executions.data || [])
            setMaxWidth(Math.max(...executions.data.map(x => x.Duration)))
            setFlat({
                executions: flatten(executions)
            })
        }
    }, [executions])

    const handleFilter = filter => {
        if (executions?.data) {
            let newData = executions.data.filter(x => (
                ((filter.search || '').length > 0 ? x.JobName.toLowerCase().includes(filter.search.toLowerCase()) : true) &&
                ((filter.Status || '').length > 0 ? x.Status === filter.Status : true) &&
                ((filter.system || []).length > 0 ? filter.system.some(system => x.StateMachine.split('-').indexOf(system) >= 0) : true)
            ))
            setData(newData)
        }
    }

    return (
        <Navbar>
            <Grid container>
                <Header
                    type="Execution"
                    setData={setExecutions}
                    data={executions.data}
                    hideNew={true}
                />

                <ControlPanel
                    page="Executions"
                    handler={handleFilter}
                    filter={executions === null}
                />

                {/* Results */}
                <RowResults
                    type="executions"
                    loading={!executions.data}
                    data={data}
                    handlers={{}}
                    matches={{
                        executions: x => flat?.executions?.[x] || []
                    }}
                    maxWidth={maxWidth}
                />
            </Grid>
        </Navbar>
    );
}

export default Executions;