import { useAuth } from "react-oidc-context";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import logo from '../images/logo.svg';
import Executions from './Executions';
import LinearProgress from '@mui/material/LinearProgress';

const CenterBox = props => {
    return (
        <Box sx={{
            position: 'absolute',
            // background: 'pink',
            top: '25%',
            left: '25%',
            bottom: '25%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            width: '50%',
        }}>
            <Grid container sx={{
                width: '50%',
                '& .MuiGrid-root': { width: '100%', textAlign: 'center' }
            }}>
                <Grid>
                    <img src={logo} alt="dash" style={{ maxHeight: '45px' }} />
                </Grid>
                <Grid>
                    {props.children}
                </Grid>
            </Grid>
        </Box>
    )
}

export default function Login() {
    const auth = useAuth();
    if (auth.isLoading) {
        return (
            <CenterBox>
                <LinearProgress />
            </CenterBox>
        )
    }
    return auth.isAuthenticated ?
        <Executions />
        :
        <CenterBox>
            <Button variant="outlined" onClick={() => auth.signinRedirect()}>Sign in</Button>
        </CenterBox>

}