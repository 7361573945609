import Grid from '@mui/material/Grid';
import TitleLink from "../Shared/TitleLink";
import { SystemConfig } from '../Config/SystemConfig';
import Match from "../Match/Match";

const RowSchema = props => {
    return (
        <Grid container className="ContainerRow">
            <Grid item xs={5}>
                <TitleLink
                    href={`/schemas/${props.item.Id}`}
                    avatars={[{
                        system: SystemConfig?.[props.item.SystemKey],
                    }]}
                    label={props.item.Name}
                />
            </Grid>
            <Grid item xs={2}>
                <Match type="executions" matches={props.matches.executions} handler={() => props.getExecutions(props.matches.jobs.map(x => x.Id))}/>
            </Grid>
            <Grid item xs={5}>
                <Match type="jobs" matches={props.matches.jobs} />
            </Grid>
        </Grid>
    )
}

export default RowSchema