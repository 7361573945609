import { SystemConfig } from '../Config/SystemConfig';
import Grid from '@mui/material/Grid';
import ExecutionBlock from '../Execution/ExecutionBlock';
import TitleLink from "../Shared/TitleLink";
import Match from '../Match/Match';

const RowExecution = props => {
    const source = SystemConfig?.[props.item.StateMachine.split('-')[0]] || {}
    const target = SystemConfig?.[props.item.StateMachine.split('-')[1]] || {}
    return (
        <Grid container className="ContainerRow">
            <Grid item xs={4}>
                <TitleLink
                    href={`/jobs/${(props.item.GSI1PK || '').substring(4)}`}
                    avatars={[
                        { system: source },
                        { system: target }
                    ]}
                    label={props.item.JobName}
                />
            </Grid>

            <Grid item xs={6}>
                <ExecutionBlock
                    item={props.item}
                    maxWidth={props.maxWidth}
                    href
                />
            </Grid>
            <Grid item xs={2}>
                <Match type="executions" matches={props.matches.executions} />
            </Grid>
        </Grid>
    )
}

export default RowExecution;