import { Grid, Typography } from '@mui/material';
import Button from '../Buttons/Button';
import Pod from './Pod';
import pluralize from 'pluralize';

const Subtitle = props => (
    <Grid container>
        <Grid item xs sx={{
            fontSize: '1.25rem',
            fontWeight: 500,
            alignItems: 'center',
            display: 'flex',
            mb: 0.5
        }}>
            {pluralize(props.type)}
            {props.count > 0 && <Pod>{props.count}</Pod>}
        </Grid>
        <Grid>
            {props.new &&
                <Button
                    type="new"
                    label={`New ${props.type}`}
                    handler={props.handler}
                />
            }
            {props.refresh &&
                <Button
                    type="refresh"
                    handler={props.handler}
                />
            }
        </Grid>
    </Grid>
)

export default Subtitle;