const ExecutionDetailMap = {
    ExecutionStarted: {
        key: "executionStartedEventDetails",
        itemKey: "input",
        parent: "Executions",
        type: "start",
        status: null
    },
    ExecutionSucceeded: {
        key: "executionSucceededEventDetails",
        itemKey: "output",
        parent: "Executions",
        type: "end",
        status: "success",
        Status: "Succeeded"
    },
    ExecutionFailed: {
        key: "executionFailedEventDetails",
        itemKey: "cause",
        parent: "Executions",
        type: "end",
        status: "error",
        Status: "Failed"
    },
    TaskScheduled: {
        key: "taskScheduledEventDetails",
        itemKey: "parameters",
        parent: "Tasks",
        type: "start"
    },
    TaskSucceeded: {
        key: null,
        itemKey: "parameters",
        parent: "Tasks",
        type: "end",
        status: "success",
        Status: "Succeeded"
    },
    MapStateEntered: {
        key: "stateEnteredEventDetails",
        itemKey: "input",
        parent: "Map",
        type: "start"
    },
    MapStateStarted: {
        key: "mapStateStartedEventDetails",
        itemKey: "input",
        parent: "Map",
        type: "start"
    },
    MapStateFailed: {
        key: null,
        itemKey: "input",
        parent: "Map",
        type: "end",
        status: "error",
        Status: "Failed"
    },
    MapIterationStarted: {
        key: "mapIterationStartedEventDetails",
        itemKey: "input",
        parent: "Map",
        type: "start"
    },
    MapIterationSucceeded: {
        key: "mapIterationSucceededEventDetails",
        itemKey: "stack",
        parent: "Map",
        type: "end"
    },
    MapIterationFailed: {
        key: "mapIterationFailedEventDetails",
        itemKey: null,
        parent: "Map",
        type: "end",
        status: "error",
        Status: "Failed"
    },
    TaskStateEntered: {
        key: "stateEnteredEventDetails",
        itemKey: "input",
        parent: "Tasks",
        type: "start"
    },
    TaskStateExited: {
        key: "stateExitedEventDetails",
        itemKey: "input",
        parent: "Tasks",
        type: "end",
        status: "success",
        Status: "Succeeded"
    },
    TaskStateAborted: {
        key: null,
        itemKey: "input",
        parent: "Tasks",
        type: "end",
        status: "neutral",
        Status: "Aborted"
    },
    LambdaFunctionScheduled: {
        key: "lambdaFunctionScheduledEventDetails",
        itemKey: "input",
        parent: "Lambda",
        type: "start"
    },
    LambdaFunctionStarted: {
        key: null,
        itemKey: "input",
        parent: "Lambda",
        type: "start"
    },
    LambdaFunctionSucceeded: {
        key: "lambdaFunctionSucceededEventDetails",
        itemKey: "input",
        parent: "Lambda",
        type: "end",
        status: "success",
        Status: "Succeeded"
    },
    LambdaFunctionFailed: {
        key: "lambdaFunctionFailedEventDetails",
        itemKey: "cause",
        parent: "Lambda",
        type: "end",
        status: "error",
        Status: "Failed"
    }
}

export default ExecutionDetailMap