import React from 'react';
import Grid from '@mui/material/Grid';
import Button from './Button';
import ButtonSwitch from './ButtonSwitch';

const ActionButtons = props => {
    const css = {
        display: 'flex',
        textAlign: 'right',
        alignItems: 'center',
        '& .MuiButton-root': { ml: 1 }
    }
    return (
        <Grid item sx={css}>
            {/* {props.enable  && <ButtonSwitch label={["Enabled", "Disabled"]} {...props} />} */}
            {props.enable  && <ButtonSwitch type="enable" handler={props.enable} status={props.enabled} />}
            {props.delete  && !props.createNew && <Button type="delete" handler={props.delete} />}
            {props.run     && <Button type="run" handler={props.run} color="secondary" />}
            {props.query   && <Button type="query" handler={props.query} color="secondary" />}
            {props.test    && <Button type="test" handler={props.test} />}
            {props.save    && <Button type="save" handler={props.save} createNew={props.createNew} />}
            {props.refresh && <Button type="refresh" handler={props.refresh} />}
            {props.new     && <Button type="new" handler={props.new} color="success" />}
        </Grid>
    )
}
export default ActionButtons;