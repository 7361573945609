import { useState, useEffect, useMemo } from "react";
import { useSelector, } from 'react-redux';

import Subtitle from '../Shared/Subtitle';
import Grid from '@mui/material/Grid';
import JobRecordRow from '../Job/JobRecordRow';

import { SystemConfig, SystemConfigFlat } from '../Config/SystemConfig';
import { getConfig } from '../Shared/Functions';

const JobRecords = props => {
    const schemas = useSelector(state => state.schemas);

    const [input, setInput] = useState(props.input)
    const [node, setNode] = useState(props.input.target.system === 'sqlserver' ? 'source' : 'target')
    const [options, setOptions] = useState({})

    useEffect(() => {
        const newNode = props.input.target.system === 'sqlserver' ? 'source' : 'target'
        setInput(props.input)
        setNode(newNode)
        setOptions({
            names: makeOptionsNames(newNode),
            params: makeOptionsParams(newNode)
        })
    }, [props, schemas.data])

    const makeOptionsNames = node => {
        return schemas.data.filter(x =>
            x.SystemKey === props.input[node].system
        ).map(x => ({
            value: x.Name,
            label: x.Name
        }))
    }

    const makeOptionsParams = node => {
        let newOpts = {}
        schemas.data.filter(x =>
            x.SystemKey === props.input[node].system
        ).forEach(x =>
            newOpts[x.Name] = [
                ...Object.keys(x.Fields),
                ...getConfig('params', input, node)
            ].sort().map(y => ({
                value: y,
                label: y
            }))
        )
        return newOpts
    }

    const attributes = input && SystemConfig[input.source.system] && SystemConfig[input.target.system] && [
        ...((SystemConfig[input.source.system || ''].types[input.source.type || '']['source'][input.source.action || ''] || {}).attributes || []),
        ...((SystemConfig[input.target.system || ''].types[input.target.type || '']['target'][input.target.action || ''] || {}).attributes || [])
    ]

    const handleRecordChange = (e, key) => {
        let newRecords = [...input?.records || []]

        if (e.target.name === "record_order") {
            newRecords.splice(key + e.target.value, 0, newRecords.splice(key, 1)[0])
        } else if (e.target.name === "add") {
            newRecords = [...(newRecords || []), ...[{ object: '' }]]
        } else if (e.target.name === "delete") {
            newRecords.splice(key, 1)
        } else {
            newRecords[key] = {
                ...newRecords[key],
                ...{ [e.target.name]: e.target.value }
            }
            !newRecords[key].params && delete newRecords[key].params
        }
        setInput({
            ...input,
            ...{ records: newRecords }
        })
        props.handler({ target: { name: 'Input', input: 'records', value: newRecords } })
    }

    return (
        <Grid container className="Container">
            <Subtitle
                type="Record"
                new
                handler={() => handleRecordChange({ target: { name: "add" } })}
            />

            {/* Records */}
            {input?.records && input.records.map((item, key) => {
                return (
                    <JobRecordRow
                        key={key}
                        item={item}
                        node={node}
                        attributes={attributes}
                        options={options}
                        handler={e => handleRecordChange(e, key)}
                    />
                )
            })}
        </Grid>

    )
}
export default JobRecords;