import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  connectionsReducer,
  executionsReducer,
  jobsReducer,
  queriesReducer,
  schemasReducer,
  sysconfigReducer,
  systemsReducer,
  usersReducer
} from './slices';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
}

const appReducer = combineReducers({
  connections: connectionsReducer,
  executions: executionsReducer,
  jobs: jobsReducer,
  queries: queriesReducer,
  schemas: schemasReducer,
  sysconfig: sysconfigReducer,
  systems: systemsReducer,
  users: usersReducer
})

const rootReducer = (state, action) => {
  // clear the cache when user logs out
  if (action.type === 'RESET') {
    storage.removeItem('persist:root')
    return appReducer(undefined, action);
  }
  // otherwise update the corresponding cache
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})

export const persistor = persistStore(store)
