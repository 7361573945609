import Grid from '@mui/material/Grid';
import TitleLink from "../Shared/TitleLink";
import Title from "../Shared/Title2";
import Pod from '../Shared/Pod';
import { SystemConfig } from '../Config/SystemConfig';
import Match from "../Match/Match";
import { iconType } from '../Shared/Functions';

const RowConnection = props => {
    const type = iconType("Connections", props.item.SystemType)
    return (
        <Grid container className="ContainerRow">
            <Grid item xs={4}>
                <Grid container>
                    <Title
                        row
                        href={`/connections/${props.item.Id}`}
                        avatars={props.avatars}
                        label={props.item.Name}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Pod
                    icon={type.icon}
                    color={type.color}
                >
                    {type.label}
                </Pod>
            </Grid>
            <Grid item xs={4}>
                <Match type="jobs" matches={props.matches.jobs} />
            </Grid>
        </Grid>
    )
}

export default RowConnection;