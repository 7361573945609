import Grid from '@mui/material/Grid';
import TitleLink from "../Shared/TitleLink";
import Match from "../Match/Match";
import JoinLeftIcon from '@mui/icons-material/JoinLeft';

const Row = props => {
    return (
        <Grid container className="ContainerRow">
            <Grid item xs={4}>
                <Grid container>
                    <TitleLink
                        href={`/queries/${props.item.Id}`}
                        icons={[{
                            icon: <JoinLeftIcon />,
                        }]}
                        label={props.item.Name}
                    />
                </Grid>
            </Grid>
            <Grid item xs={8}>
                <Grid container sx={{border: '0px solid'}}>
                    <Grid item xs={6}>
                        <Match type="schemas" matches={props.matches.schemas} />
                    </Grid>
                    <Grid item xs={6}>
                        <Match type="jobs" matches={props.matches.jobs} />
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default Row