import { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { useUser } from '../Auth/AuthContext';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import ButtonsConfig from './ButtonConfig';

const ButtonSwitch = props => {
    const user = useUser();
    const theme = useTheme();
    const btn = ButtonsConfig[props.type]

    const [checked, setChecked] = useState(props.status);
    useEffect(() => {
        setChecked(props.status);
    }, [props])

    return (
        <FormControlLabel
            control={
                <Switch
                    name={props.type}
                    color={btn.color || "primary"}
                    checked={checked}
                    onChange={props.handler}
                    size={props.small ? "small" : "medium"}
                    disabled={props.disabled || (user?.role === "Admin" ? false : btn.disabled)}
                />
            }
            label={!props.small &&
                <Typography
                    sx={{
                        color: theme.palette[checked ? 'success' : 'neutral'].main,
                    }}
                >
                    {checked ? btn.labelOn : btn.labelOff}
                </Typography>
            }
        />
    )
}
export default ButtonSwitch;