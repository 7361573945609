import Grid from '@mui/material/Grid';
import TitleLink from "../Shared/TitleLink2";
import Title from "../Shared/Title2";
import { SystemConfig } from '../Config/SystemConfig';
import Match from "../Match/Match";

const Row = props => {
    console.log(SystemConfig)
    return (
        <Grid container className="ContainerRow">
            <Grid item xs={5}>
                <Title
                    row
                    href={`/systems/${props.item.Id}`}
                    avatars={props.avatars}
                    label={props.item.Name}
                />
            </Grid>
            {/* <Grid item xs={2}>
                <Match type="executions" matches={props.matches.executions} handler={() => props.getExecutions(props.matches.jobs.map(x => x.Id))}/>
            </Grid>
            <Grid item xs={5}>
                <Match type="jobs" matches={props.matches.jobs} />
            </Grid> */}
        </Grid>
    )
}

export default Row