import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material';

const AvatarSystem = props => {
    const theme = useTheme();
    const color = theme.palette[props?.color || 'neutral'].main
    return (
        <Badge
            badgeContent={props.badge?.icon}
            className="AvatarSystem"
            sx={{ color: color, '.MuiAvatar-root': { color: color } }}
        >
            <Avatar
                src={props.system?.icon}
                sx={{ color: color }}
            >
                {props?.icon}
            </Avatar>
        </Badge>
    )

}
export default AvatarSystem;