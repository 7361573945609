import { useState } from "react";
import { Grid, InputLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Pod from '../Shared/Pod';
import Match from "../Match/Match";

import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { EditorView } from '@codemirror/view';

const FormSQLEditor = (props) => {
    const schemas = useSelector(state => state.schemas);
    const [query, setQuery] = useState(props.query || "");

    const matches = (props.tables || []).map(x => (schemas.data || []).find(y =>
        y.SystemKey === x.split('.').splice(-2)[0] && y.Name == x.split('.').splice(-2)[1]
    ) || {
        SystemKey: x.split('.').splice(-2)[0],
        Name: x.split('.').splice(-2)[1]
    })

    return (
        <Grid item xs={12} sx={{ fontSize: '12px', '& .cm-editor': { border: '1px solid #eee', borderRadius: '4px' } }}>
            <InputLabel shrink>Query</InputLabel>
            <CodeMirror
                value={query}
                theme="light"
                minHeight="200px"
                basicSetup={{
                    foldGutter: true
                }}
                extensions={[sql(), EditorView.lineWrapping]}
                onChange={value => {
                    props.handler({ target: { name: 'query', value: value } })
                }}
            />
            {props.tables && <Match type="schemas" matches={matches} />}
        </Grid>
    )
}
export default FormSQLEditor;