import Pod from '../Shared/Pod';
import TitleLink from '../Shared/TitleLink';
import { SystemConfig } from '../Config/SystemConfig';
import Filters from '../Shared/Filters';
import { makeDate, makeDatePrint } from '../Shared/Time';

const MatchLink = props => {
    const executionLabel = x => {
        const date = makeDatePrint(makeDate({ start: x.StartTime }).date, true)
        const duration = new Date(props.item.Duration * 1000).toISOString().slice(14, 19)
        return `${date} (${duration})`
    }
    const typeMap = {
        jobs: {
            avatars: x => [
                { system: SystemConfig?.[x.Input.source.system] },
                { system: SystemConfig?.[x.Input.target.system] }
            ],
            icons: x => null,
            label: x => x.Name,
            href: x => x?.Id && `/${props.type}/${x.Id}`,
            color: x=>Filters.Jobs.Status.find(y => y.value === x.Status).color
        },
        schemas: {
            avatars: x => [{ system: SystemConfig?.[x?.SystemKey] }],
            icons: x => null,
            label: x => x?.Name,
            href: x => x?.Id && `/${props.type}/${x.Id}`
        },
        executions: {
            avatars: x => null,
            icons: x => [Filters.Executions.Status.find(y => y.value === x.Status)],
            label: x => executionLabel(x),
            href: x => x?.StateMachine && `/${props.type}/${x?.StateMachine}:${x?.ExecutionID}`
        }
    }
    return (
        <Pod icon={null} color={props.type === "jobs" ? typeMap[props.type].color(props.item) : null}>
            <TitleLink
                href={typeMap[props.type].href(props.item)}
                avatars={typeMap[props.type].avatars(props.item)}
                icons={typeMap[props.type].icons(props.item)}
                label={typeMap[props.type].label(props.item)}
            />
        </Pod>
    )
}
export default MatchLink