import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSchemas } from '../components/Cache/slices';
import { useUser } from '../components/Auth/AuthContext';
import _ from 'lodash';
import { SystemConfig } from '../components/Config/SystemConfig';

import Filters from '../components/Shared/Filters';

import { updateCache } from '../components/Shared/Functions';
import ActionButtons from '../components/Buttons/ActionButtons';
import Title from '../components/Shared/Title';
import Subtitle from '../components/Shared/Subtitle';
import Grid from '@mui/material/Grid';
import FormSelect from '../components/Form/FormSelect';
import SchemaField from '../components/Schema/SchemaField';
import SelectSystem from '../components/Select/SelectSystem';
import LinearProgress from "@mui/material/LinearProgress";

import { api } from '../components/Functions/API';
import Navbar from './Navbar';
import Modal from '../components/Shared/Modal';

const fieldsToArray = fields => {
    return Object.entries(fields).map(([name, col]) => ({ ...{ name: name }, ...col })).sort((a, b) => a.column_order - b.column_order) || []
}

const arrayToFields = fields => {
    let newFields = {}
    fields.map(x => newFields[x.name] = { ...{ type: x.type, column_order: x.column_order }, ...x.primary_key ? { primary_key: true } : {} })
    return newFields
}

const Schema = () => {
    const dispatch = useDispatch();
    const user = useUser();
    const params = useParams();
    const createNew = (window.location.pathname.split('/').pop() === "new")

    const schemas = useSelector(state => state.schemas);
    const [data, setData] = useState();
    const [snackbar, setSnackbar] = useState(null);
    const [modal, setModal] = useState();

    // Schema-specific
    const [filter, setFilter] = useState({});
    const [fields, setFields] = useState([]);

    useEffect(() => {
        user.org && getData()
    }, [user])
    
    const getData = async () => {
        if (createNew) { setData({}); return }
        const response = await api(user, 'GET', 'schemas', params.id, null, [
            x => setData(x),
            x => setFields(fieldsToArray(x.Fields || {})),
        ])
        response && setSnackbar(response)
    }
    const saveData = async () => {
        setSnackbar({ severity: 'info', message: "Saving Changes" })
        const { PK, SK, LastModified, ...body } = { ...data, ...{ Type: "schema" } }
        const response = await api(user, 'PUT', 'schemas', params.id, body, [
            x => setData(x),
            x => updateCache(dispatch, setSchemas, schemas, x)
        ])
        response && setSnackbar(response)
    }
    const deleteData = async () => {
        setSnackbar({ severity: 'info', message: `Deleting ${data.Name}` })
        const response = await api(user, 'DELETE', 'schemas', params.id, null, [
            x => updateCache(dispatch, setSchemas, schemas, x, params.id)
        ])
        response && setSnackbar(response)
    }

    const handleChange = (e, i) => {
        let newData = { ...data }
        let newFields = [...fields]

        // Field change
        if (i !== undefined) {
            if (e.target.name === "column_order") {
                newFields.splice(i + e.target.value, 0, newFields.splice(i, 1)[0])
                newFields = newFields.map((x, i) => ({ ...x, ...{ column_order: i + 1 } }))
            } else if (e.target.name === "delete") {
                newFields.splice(i, 1)
            } else if (i === -1) {
                newFields = [...newFields, ...[{ name: '', type: '', column_order: newFields.length + 1 }]]
            } else {
                newFields[i] = { ...newFields[i], ...{ [e.target.name]: e.target.value } }
            }
            // Name, SystemKey, SchemaType change
        } else {
            newData = { ...newData, ...{ [e.target.name]: e.target.value } }
        }

        newData = {
            ...newData,
            ...{
                Fields: arrayToFields(newFields),
                GSI1PK: `sys#${newData.SystemKey}`,
                GSI1SK: `sch#${newData.Name}`
            }
        }
        setData(newData)
        setFields(newFields)
    }

    // Schema-specific 

    const filtered = arr => {
        return arr.filter(x => (
            (filter.name ? x.name.toLowerCase().includes(filter.name.toLowerCase()) : true) &&
            (filter.type ? x.type === filter.type : true) &&
            (filter.primary_key ? x.primary_key : true)
        ))
    }

    return (
        <Navbar snackbar={snackbar}>
            <Modal data={modal} close={() => setModal()} delete={deleteData} />
            {data ?
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container>
                            <Title
                                page="Schemas"
                                value={data.Name}
                                avatars={[{ system: SystemConfig[data.SystemKey] }]}
                                type={data.SchemaType}
                                handler={handleChange}
                            />
                            <ActionButtons
                                createNew={createNew}
                                delete={() => setModal(`Are you sure you want to delete ${data.Name}?`)}
                                save={saveData}
                            />

                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className="Container">
                            <Subtitle
                                type="Type"
                            />
                            <SelectSystem
                                name="SystemKey"
                                value={data.SystemKey}
                                handler={e => handleChange(e)}
                            />
                            <FormSelect
                                name="SchemaType"
                                label="Schema Type"
                                value={data.SchemaType || ''}
                                options={Filters.Schemas.type}
                                disabled={!createNew}
                                handler={e => handleChange(e)}
                            />
                        </Grid>
                    </Grid>

                    {/* Records */}

                    <Grid item xs={12}>
                        <Grid container className="Container">
                            <Subtitle
                                type="Field"
                                new
                                handler={e => handleChange(e, -1)}
                            />

                            {/* Filter */}
                            {fields && fields.length > 0 &&
                                <SchemaField
                                    header
                                    handler={e => setFilter({ ...filter, ...{ [e.target.name]: e.target.value } })}
                                    item={filter}
                                />
                            }
                            {/* Fields */}
                            {filtered(fieldsToArray(data.Fields || {})).map((item, key) => {
                                return (
                                    <SchemaField
                                        item={item}
                                        key={key}
                                        handler={e => handleChange(e, key)}
                                    />
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid item xs={12}><LinearProgress /></Grid>
            }
        </Navbar>
    )
}

export default Schema;