import { useState, useEffect, createContext, useContext } from 'react';
import { useAuth } from "react-oidc-context";

// initialize the context with an empty object
const UserContext = createContext({});

export default function AuthContext({ children }) {
  // Store the user in a state variable
  const [user, setUser] = useState();
  const auth = useAuth();

  useEffect(() => {
    checkUser();
  }, [auth]);

  async function checkUser() {
    try {
      if (auth.isAuthenticated && auth?.user) {
        // set the user in state
        setUser({
            ...auth?.user,
            ...auth?.user?.profile,
            ...{
              role: auth.user.profile?.["role"],
              org: auth.user.profile?.["custom:organization"]
            }
        });
      }
    } 
    // Error occurs if there is no user signed in.
    catch (error) {
      // set the user to null if there is no user. 
      setUser(null);
    }
  }

  return (
    <UserContext.Provider
      value={{...user}}
    >
      {children}
    </UserContext.Provider>
  );
}

// export the hook so we can use it in other components.
export const useUser = () => useContext(UserContext);