import Grid from '@mui/material/Grid';
import Filters from '../Shared/Filters';
import TitleLink from "../Shared/TitleLink";

const Row = props => {
    const role = Filters.Users.Role.find(x => x.value === props.item.Role)

    return (
        <Grid container className="ContainerRow">
            <Grid item xs={6}>
                <TitleLink
                    href={`/users/${props.item.Id}`}
                    icons={[{
                        icon: role.icon
                    }]}
                    label={`${props.item.FirstName} ${props.item.LastName}`}
                />
            </Grid>
            <Grid item xs={3}>
                {props.item.Email}
            </Grid>
            <Grid item xs={3} sx={{ justifyContent: 'flex-end' }}>

            </Grid>

        </Grid>
    )
}

export default Row