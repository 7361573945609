import React, { useState, useEffect } from "react";
import { useUser } from '../components/Auth/AuthContext';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setJobs, setExecutions } from '../components/Cache/slices';

import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

import Navbar from "./Navbar";
import ActionButtons from '../components/Buttons/ActionButtons';
import JobConnections from '../components/Job/JobConnections';
import JobDescription from '../components/Job/JobDescription';
import JobExecutions from '../components/Job/JobExecutions';
import JobRecords from '../components/Job/JobRecords';
import JobSchedule from '../components/Job/JobSchedule';
import Title from '../components/Shared/Title';
import { updateCache, makeJobPayload } from '../components/Shared/Functions';
import { api } from '../components/Functions/API';
import { SystemConfig, SystemConfigFlat } from "../components/Config/SystemConfig";
import actions from '../components/Config/actions';
import { iconType } from '../components/Shared/Functions';
import Modal from '../components/Shared/Modal';

const Job = () => {
    const dispatch = useDispatch();
    const user = useUser();
    const params = useParams();
    const createNew = (window.location.pathname.split('/').pop() === "new")

    const jobs = useSelector(state => state.jobs);
    const executions = useSelector(state => state.executions);

    const [data, setData] = useState();
    const [snackbar, setSnackbar] = useState(null);
    const [modal, setModal] = useState();
    const [jobExecutions, setJobExecutions] = useState(executions.data ? executions.data.filter(x => (x.GSI1PK || '').substring(4) === params.id) : [])

    useEffect(() => {
        user.org && getData()
    }, [user])

    useEffect(() => {
        const jobExecutions = executions?.data?.filter(x => (x.GSI1PK || '').substring(4) === params.id)
        jobExecutions && jobExecutions.length > 0 && setJobExecutions(jobExecutions)
    }, [executions])

    const getData = async () => {
        if (createNew) { setData({}); return }
        const response = await api(user, 'GET', 'jobs', params.id, null, [
            x => setData(x)
        ])
        response && setSnackbar(response)
    }
    const saveData = async () => {
        setSnackbar({ severity: 'info', message: "Saving Changes" })
        const { PK, SK, LastModified, Executions, ...body } = { ...data, ...{ Type: "job" } }
        const response = await api(user, 'PUT', 'jobs', params.id, body, [
            x => setData(x),
            x => updateCache(dispatch, setJobs, jobs, x)
        ])
        response && setSnackbar(response)
    }
    const deleteData = async () => {
        setSnackbar({ severity: 'info', message: `Deleting ${data.Name}` })
        const response = await api(user, 'DELETE', 'jobs', params.id, null, [
            x => updateCache(dispatch, setJobs, jobs, x, params.id)
        ])
        response && setSnackbar(response)
    }
    const runExecution = async () => {
        setSnackbar({ severity: 'info', message: `Starting ${data.Name}` })
        const response = await api(user, "POST", "executions", '', {
            StateMachine: data.StateMachine,
            Input: { ...data.Input, jobId: data.Id }
        }, [])
        response && setSnackbar(response)
    }

    const getExecutions = async (jobId) => {
        // clear jobId executions from cached executions
        const maxExecutions = 10
        const otherExecutions = [...executions.data].filter(x => (x.GSI1PK || '').substring(4) !== jobId)
        dispatch(setExecutions(otherExecutions))
        setJobExecutions(null)

        const response = await api(user, 'GET', 'jobs', `${jobId}/executions`, null, [
            x => dispatch(setExecutions([
                ...otherExecutions,
                ...x.splice(0, maxExecutions)
            ].sort((a, b) => { return b.SK - a.SK })))
        ])
        response && setSnackbar(response)
    }

    const handleChange = e => {
        setSnackbar(null)
        let payload = { [e.target.name]: e.target.value }

        // update Input and update Input.jobName if Name changed

        // HACK ALERT, kippdc SHOULD NOT BE HARDCODED HERE, FIX THIS LATER
        if (e.target.input || e.target.name === 'Name') {
            let key = e.target.name === 'Name' ? 'jobName' : e.target.input;
            let Name = e.target.name === 'Name' ? { 'Name': e.target.value } : {}
            payload = makeJobPayload(data, user?.profile["custom:organization"], key, e.target.value, Name)
            // payload = makeJobPayload(data, 'kippdc', key, e.target.value, Name)
        }
        const stateMachine = data.Input && data.Input.source && data.Input.target && data.Input.source.system + '-' + data.Input.target.system

        const newData = {
            ...data,
            ...payload,
            ...{ StateMachine: stateMachine }
        }
        setData(newData)
    }

    // Jobs-specific

    const handleEnable = e => {
        setData({ ...data, ...{ Status: e.target.checked ? "ENABLED" : "DISABLED" } })
    }

    return (
        <Navbar snackbar={snackbar}>
            <Modal data={modal} close={() => setModal()} delete={deleteData} />
            {data ?
                <Grid container>

                    {/* Header */}

                    <Grid item xs={12}>
                        <Grid container>
                            <Title
                                page="Connections"
                                value={data.Name}
                                handler={handleChange}
                                // systems={systems}
                                // avatars={[{
                                //     system: SystemConfig?.[data.Input.source.system],
                                // },
                                // {
                                //     system: SystemConfig?.[data.Input.target.system],
                                // }]}
                                avatars={data.Input && [{
                                    system: SystemConfig?.[data.Input.source.system],
                                    badge: actions[data.Input.source.action],
                                    color: iconType('Connections', data.Input.source.type).color
                                },
                                {
                                    system: SystemConfig?.[data.Input.target.system],
                                    badge: actions[data.Input.target.action],
                                    color: iconType('Connections', data.Input.target.type).color
                                }]}
                            />

                            <ActionButtons
                                type="job"
                                createNew={createNew}
                                enabled={data.Status === "ENABLED"}
                                enable={handleEnable}
                                delete={() => setModal(`Are you sure you want to delete ${data.Name}?`)}
                                run={runExecution}
                                save={saveData}
                            />
                        </Grid>
                    </Grid>

                    {/* Config Left */}

                    <Grid item xs={6} sx={{ pr: 0.5 }}>

                        <JobConnections
                            input={data.Input || { source: {}, target: {} }}
                            // input={data.Input}
                            handler={handleChange}
                        />

                        <JobDescription
                            value={data.Description}
                            handler={handleChange}
                        />

                    </Grid>

                    {/* Config Right */}

                    <Grid item xs={6} sx={{ pl: 0.5 }}>
                        <JobSchedule
                            schedule={data.Schedule || ''}
                            handleEnable={handleEnable}
                            handler={handleChange}
                        />
                        <JobExecutions
                            handler={() => getExecutions(params.id || -1)}
                            data={jobExecutions}
                        />
                    </Grid>

                    {/* Records */}

                    <Grid item xs={12}>
                        <JobRecords
                            input={data.Input || { source: {}, target: {}, records: [] }}
                            queries={data.Config}
                            handler={handleChange}
                        />
                    </Grid>
                </Grid>
                :
                <Grid item xs={12}><LinearProgress /></Grid>
            }
        </Navbar>
    )
}

export default Job;