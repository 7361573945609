import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Button from '../Buttons/Button';
import JobRecordRowParameter from './JobRecordRowParameter';

const JobRecordRowParameters = props => {
    const [item, setItem] = useState(props.item)
    const [options, setOptions] = useState(props.options)

    useEffect(() => {
        setItem(props.item)
        setOptions(props.options)
    }, [props])

    const handleParamChange = (e, key) => {
        const newItem = { ...item }
        if (e.target.name === "add") {
            newItem.params = [...newItem.params || [], ...[{ field: "", operator: "", value: "" }]]
        } else if (e.target.name === "delete") {
            const newParams = [...newItem.params]
            newParams.splice(key, 1)
            newItem.params = newParams
        } else {
            const newParams = [...newItem.params]
            newParams[key] = {
                ...newParams[key] || {},
                ...{ [e.target.name]: e.target.value }
            }
            newItem.params = newParams
        }
        props.handler({ target: { name: "params", value: newItem.params.length > 0 && newItem.params } })
    }

    return (
        // <Grid item xs={props.width}>
            <Grid container>
                <Tooltip title={"Parameters"}>
                    <Grid item>
                        <Button small type="new" handler={() => handleParamChange({ target: { name: "add" } })} />
                    </Grid>
                </Tooltip>
                <Grid item xs>
                    {item.params && item.params.map((param, key) => {
                        return (
                            param &&
                            <JobRecordRowParameter
                                key={key}
                                {...item}
                                {...param}
                                params={options}
                                options={options}
                                handler={e => handleParamChange(e, key)}
                            />
                        )
                    })}
                </Grid>
            </Grid>
        // </Grid>
    )
}
export default JobRecordRowParameters;