import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import AvatarSystem from '../Icons/AvatarSystem';
import { useTheme } from '@mui/material/styles';

const TitleLink = props => {
    const theme = useTheme()
    const avatars = props?.avatars || []
    const icons = props?.icons || []
    
    return (
        <Link href={props.href} target="_blank" className="TitleLink"
            underline={props.href ? 'hover' : 'none'}
            sx={{
                color: theme.palette[props.href ? 'primary' : 'neutral'].main,
                cursor: props.href ? 'pointer' : 'auto'
            }}
        >
            {avatars.map((avatar, key) => (
                <AvatarSystem
                    key={key}
                    system={avatar?.system}
                    badge={avatar?.badge}
                />
            ))}
            {icons.map((icon, key) => (
                <Avatar
                    key={key}
                    sx={{
                        color: theme.palette[icon?.color || 'primary'].main
                    }}
                >

                    {icon.icon}
                </Avatar>
            ))}
            <Typography>
                {props.label || "_"}
            </Typography>
        </Link>
    )
}

export default TitleLink