import { ThemeProvider, createTheme } from '@mui/material/styles';
import MuiTheme from './MuiTheme';
import AuthContext from './components/Auth/AuthContext';
import './dashapp.css';
import Router from './pages/Router';


export default function App() {
    return (
        <ThemeProvider theme={createTheme(MuiTheme)}>
            <AuthContext>
                <Router />
            </AuthContext>
        </ThemeProvider>
    );
}