import { SystemConfig } from '../Config/SystemConfig';

import Time from '../Shared/Time';
import actions from '../Config/actions';
import { iconType } from '../Shared/Functions';
import ButtonSwitch from "../Buttons/ButtonSwitch";
import Button from "../Buttons/Button";
import Match from '../Match/Match';

import Grid from '@mui/material/Grid';
import TitleLink from "../Shared/TitleLink";


const RowJob = props => {
    return (
        <Grid container className="ContainerRow">
            <Grid item xs={4}>
                <Grid container>
                    <TitleLink
                        href={`/jobs/${props.item.Id}`}
                        avatars={[
                            {
                                system: SystemConfig?.[props.item.Input.source.system],
                                badge: actions[props.item.Input.source.action],
                                color: iconType('Connections', props.item.Input.source.type).color
                            },
                            {
                                system: SystemConfig?.[props.item.Input.target.system],
                                badge: actions[props.item.Input.target.action],
                                color: iconType('Connections', props.item.Input.target.type).color
                            }
                        ]}
                        label={props.item.Name}
                    />
                </Grid>

            </Grid>
            <Grid item xs={1}>
                <Time cron={props.item.Schedule} />
            </Grid>
            <Grid item xs={1}>
                <ButtonSwitch
                    small
                    type="enable"
                    status={props.item.Status === "ENABLED"}
                    handler={e => props.handleJobEnable(e, props.item.Id)}
                />
            </Grid>
            <Grid item xs={5}>
                <Grid container>
                    <Grid item xs={6}>
                        <Match type="executions" matches={props.matches.executions} handler={() => props.getExecutions([props.item.Id])} />
                    </Grid>
                    <Grid item xs={6}>
                        <Match type="schemas" matches={props.matches.schemas} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <Button
                    type="run"
                    handler={() => props.jobRun(props.item)}
                />
            </Grid>
        </Grid>
    )
}


export default RowJob;